import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAvailableMeets, IAvailableMeet } from "../../models/meets";
import { getTimestamp } from "../../utils";

interface IInitialState {
	successAlertState: {
		isOpen: boolean;
		isAvailableTime: boolean;
		type: "deletion" | "change";
	};

	availableMeets: IAvailableMeets | null;
}

interface setStateSuccessAlertAction {
	isOpen: boolean;
	isAvailableTime?: boolean;
	type?: "deletion" | "change";
}

interface IAddAvailableMeetAction {
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
}

type ISetAvailableMeetsAction = IAvailableMeets;

interface IAddAvailableMeetsAction {
	date: string;
	times: string[];
	isOnline: boolean;
	isOffline: boolean;
}

interface IUpdateAvailableMeetAction {
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
}

const initialState: IInitialState = {
	successAlertState: {
		isOpen: false,
	},

	availableMeets: null,
} as IInitialState;

export const journalTableSlice = createSlice({
	name: "journalTable",
	initialState,
	reducers: {
		setStateSuccessAlert: (state, action: PayloadAction<setStateSuccessAlertAction>) => {
			const { isOpen, isAvailableTime, type } = action.payload;

			state.successAlertState.isOpen = isOpen;
			if (isAvailableTime) state.successAlertState.isAvailableTime = isAvailableTime;
			if (type) state.successAlertState.type = type;
		},

		setAvailableMeets: (state, action: PayloadAction<ISetAvailableMeetsAction>) => {
			state.availableMeets = action.payload;
		},

		addAvailableMeets: (state, action: PayloadAction<IAddAvailableMeetsAction>) => {
			const { availableMeets } = state;
			const { date, times, isOnline, isOffline } = action.payload;

			if (availableMeets) {
				times.forEach((time) => {
					const timestamp = getTimestamp(date, time);

					const newAvailableMeet: IAvailableMeet = {
						available: true,
						date,
						time,
						isOnline,
						isOffline,
						timestamp,
					};

					if (availableMeets[date]) {
						availableMeets[date].times[time] = newAvailableMeet;
					} else {
						availableMeets[date] = {
							times: {
								[time]: newAvailableMeet,
							},
						};
					}
				});
			}
		},

		addAvailableMeet: (state, action: PayloadAction<IAddAvailableMeetAction>) => {
			const { availableMeets } = state;
			const { date, time, isOnline, isOffline } = action.payload;

			if (availableMeets) {
				const timestamp = getTimestamp(date, time);

				const newAvailableMeet: IAvailableMeet = {
					available: true,
					date,
					time,
					isOnline,
					isOffline,
					timestamp,
				};

				if (availableMeets[date]) {
					availableMeets[date].times[time] = newAvailableMeet;
				} else {
					availableMeets[date] = {
						times: {
							[time]: newAvailableMeet,
						},
					};
				}
			}
		},

		updateAvailableMeet: (state, action: PayloadAction<IUpdateAvailableMeetAction>) => {
			const { availableMeets } = state;
			const { date, time, isOnline, isOffline } = action.payload;

			if (availableMeets) {
				availableMeets[date].times[time].isOnline = isOnline;
				availableMeets[date].times[time].isOffline = isOffline;
			}
		},

		deleteAvailableMeet: (state, action: PayloadAction<{ date: string; time: string }>) => {
			const { availableMeets } = state;
			const { date, time } = action.payload;

			if (availableMeets) {
				delete availableMeets[date].times[time];

				const timeList = Object.keys(availableMeets[date].times);
				if (!timeList.length) delete availableMeets[date];
			}
		},

		deleteAvailableMeets: (state) => {
			state.availableMeets = null;
		},
	},
});

export const {
	setStateSuccessAlert,
	setAvailableMeets,
	addAvailableMeets,
	addAvailableMeet,
	updateAvailableMeet,
	deleteAvailableMeet,
	deleteAvailableMeets,
} = journalTableSlice.actions;

export default journalTableSlice.reducer;
