import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyDJjzvhmkWhAnMQbagOndzjP-PxNVGgwPc",
	authDomain: "landing-page-for-psychologist.firebaseapp.com",
	projectId: "landing-page-for-psychologist",
	storageBucket: "landing-page-for-psychologist.appspot.com",
	messagingSenderId: "97935111984",
	appId: "1:97935111984:web:7740ea857474c7665a7af5",
};

export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
