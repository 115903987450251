interface IAllMeets {
	[index: string]: {
		available: boolean;
		times: {
			[index: string]: IAvailableMeet | IBookedMeet;
		};
	};
}

interface IAvailableMeets {
	[index: string]: {
		times: {
			[index: string]: IAvailableMeet;
		};
	};
}

interface IAvailableMeet {
	available: true;
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
	timestamp: number;
}

interface IBookedMeet {
	available: false;
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
	timestamp: number;
	comment: string;
	firstCall: boolean;
	messenger: string;
	phone: string;
	surname: string;
	isBookedOnline: boolean;
	isBookedOffline: boolean;
}

function isIAvailableMeet(object: IAvailableMeet | IBookedMeet): object is IAvailableMeet {
	return object.available;
}

export { isIAvailableMeet };
export type { IAllMeets, IAvailableMeets, IAvailableMeet, IBookedMeet };
