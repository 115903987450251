import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ConsultaionType } from "../../helpers/formHelpers";

interface IConsultationSliceState {
	selectedType: ConsultaionType | null;
}

const initialState: IConsultationSliceState = {
	selectedType: null,
};

const consultationSlice = createSlice({
	name: "consultationType",
	initialState,
	reducers: {
		setConsultaionType: (state: IConsultationSliceState, action: PayloadAction<ConsultaionType>) => {
			state.selectedType = action.payload;
		},
		clearConsultaionType: (state: IConsultationSliceState) => {
			state.selectedType = null;
		},
	},
});
export const { setConsultaionType, clearConsultaionType } = consultationSlice.actions;
export default consultationSlice.reducer;
