import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";

import auth from "../db/auth";

interface IAuthContext {
	user: User | null;
	logout: () => void;
}

interface IAuthProvider {
	children: ReactNode;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

function AuthProvider({ children }: IAuthProvider) {
	const [user, setUser] = useState<User | null>(null);
	const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
			} else {
				setUser(null);
			}

			setIsLoadingInitial(false);
		});
	});

	const logout = () => auth.signOut();

	const authData = {
		user,
		logout,
	};

	return <AuthContext.Provider value={authData}>{!isLoadingInitial && children}</AuthContext.Provider>;
}

function useAuth() {
	return useContext(AuthContext);
}

export default useAuth;
export { AuthProvider };
