import styles from "./Card.module.scss";

interface ICard {
	pathToPng: string;
	pathToWebp: string;
	pathToFull: string;
	isVertical: boolean;
}

function Card({ pathToPng, pathToWebp, pathToFull, isVertical }: ICard) {
	return (
		<div>
			<a href={pathToFull} target="_blank" rel="noreferrer">
				<picture>
					<source srcSet={pathToWebp} />
					<img className={`${styles.image} ${isVertical ? styles.vertical : ""}`} src={pathToPng} alt="Сертификат" />
				</picture>
			</a>
		</div>
	);
}

export default Card;
