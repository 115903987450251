import { useState } from "react";

import ScheduleIcon from "@mui/icons-material/Schedule";

import CurrentDate from "../../../components/common/currentDate/CurrentDate";

import Navigation from "./navigation/Navigation";
import AddSlot from "./addSlot/AddSlot";
import AddDay from "./addDay/AddDay";

import styles from "./Schedule.module.scss";

function Schedule() {
	const [currentTab, setCurrentTab] = useState<"slot" | "day">("slot");

	const handleClickOnNavItem = (type: "slot" | "day") => {
		setCurrentTab(type);
	};

	return (
		<section className={styles.section}>
			<div className="container">
				<div className={styles.wrapper}>
					<div className={styles.titleWrapper}>
						<ScheduleIcon fontSize="large" color="warning" />
						<h2 className={styles.title}>Расписание</h2>
					</div>
					<CurrentDate className={styles.currentDate} />

					<Navigation className={styles.nav} onNavItemClick={handleClickOnNavItem} />

					{currentTab === "slot" && <AddSlot />}
					{currentTab === "day" && <AddDay />}
				</div>
			</div>
		</section>
	);
}

export default Schedule;
