import { useEffect } from "react";

import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import auth from "../db/auth";

import icons from "../assets/images/icons/sprite.svg";

import styles from "./LoginPage.module.scss";

function LoginPage() {
	useEffect(() => {
		const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

		ui.start("#firebaseui-auth-container", {
			signInOptions: [
				{
					provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
					requireDisplayName: false,
					disableSignUp: { status: true, adminEmail: "v.kapitan2010@gmail.com" },
				},
			],
			callbacks: {
				signInSuccessWithAuthResult: function () {
					return false;
				},
			},
		});
	});

	return (
		<section className={styles.section}>
			<div className={styles.iconWrapper}>
				<svg className={styles.advantageImage} width="24" height="24">
					<use href={icons + "#lock"} />
				</svg>
			</div>
			<h2 className={styles.title}>Авторизация</h2>
			<div className={styles.firebaseContainer} id="firebaseui-auth-container"></div>
		</section>
	);
}

export default LoginPage;
