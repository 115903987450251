export const maximalZIndexValue = 110;

export const timeConstants = {
	tenMinutes: 10,
	tenHours: 10,
	twentyThreeHours: 23,
	tenDays: 10,
	nineMonths: 9,
};

export const privacyPolicyLink: string = "https://cpd.by/politika-konfidentsialnosti/";
export const developLink: string = "";

export const emailData = {
	serviceId: "service_kkivrr6",
	templateId: "template_lnv8rnm",
	publicKey: "jRQZU7xJXB6Ao32bA",
};
