import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { IAvailableMeet, IBookedMeet } from "../../models/meets";
import { getStringDateFromDayjs, getTimestamp } from "../../utils";

interface IInitialState {
	selectedDate: string;
	relevantMeetList: (IAvailableMeet | IBookedMeet)[] | null;
}

interface IUpdateAvailableMeetAction {
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
}

interface IUpdateBookedMeetAction {
	time: string;
	params: {
		comment: string;
		firstCall: boolean;
		messenger: string;
		phone: string;
		surname: string;
		isBookedOnline: boolean;
		isBookedOffline: boolean;
	};
}

interface IAddAvailableMeetsAction {
	date: string;
	times: string[];
	isOnline: boolean;
	isOffline: boolean;
}

type IAddAvailableMeetAction = IUpdateAvailableMeetAction;

type IBookMeetAction = IUpdateBookedMeetAction;

function sortMeetList(meetList: (IAvailableMeet | IBookedMeet)[]) {
	meetList.sort((a, b) => {
		if (a.time > b.time) return 1;
		if (a.time < b.time) return -1;
		return 0;
	});
}

const initialState: IInitialState = {
	selectedDate: getStringDateFromDayjs(dayjs()),
	relevantMeetList: null,
};

export const logbookSlice = createSlice({
	name: "logbook",
	initialState,
	reducers: {
		setRelevantMeetList: (state, action: PayloadAction<(IAvailableMeet | IBookedMeet)[]>) => {
			state.relevantMeetList = action.payload;
		},

		addAvailableMeets: (state, action: PayloadAction<IAddAvailableMeetsAction>) => {
			const { relevantMeetList } = state;
			const { date, times, isOnline, isOffline } = action.payload;

			if (relevantMeetList) {
				times.forEach((time) => {
					const timestamp = getTimestamp(date, time);

					const newAvailableMeet: IAvailableMeet = {
						available: true,
						date,
						time,
						isOnline,
						isOffline,
						timestamp,
					};
					relevantMeetList.push(newAvailableMeet);
				});

				sortMeetList(relevantMeetList);
			}
		},

		addAvailableMeet: (state, action: PayloadAction<IAddAvailableMeetAction>) => {
			const { relevantMeetList } = state;
			const { date, time, isOnline, isOffline } = action.payload;

			if (relevantMeetList) {
				const timestamp = getTimestamp(date, time);

				const newAvailableMeet: IAvailableMeet = {
					available: true,
					date,
					time,
					isOnline,
					isOffline,
					timestamp,
				};

				relevantMeetList.push(newAvailableMeet);
				sortMeetList(relevantMeetList);
			}
		},

		updateAvailableMeet: (state, action: PayloadAction<IUpdateAvailableMeetAction>) => {
			const { relevantMeetList } = state;
			const { time, isOnline, isOffline } = action.payload;

			if (relevantMeetList) {
				const meetIndex = relevantMeetList.findIndex((i) => i.time === time);
				relevantMeetList[meetIndex].isOnline = isOnline;
				relevantMeetList[meetIndex].isOffline = isOffline;
			}
		},

		updateBookedMeet: (state, action: PayloadAction<IUpdateBookedMeetAction>) => {
			const { relevantMeetList } = state;
			const { time, params } = action.payload;

			if (relevantMeetList) {
				const indexOfchangingMeet = relevantMeetList.findIndex((i) => i.time === time);
				relevantMeetList[indexOfchangingMeet] = {
					...relevantMeetList[indexOfchangingMeet],
					...params,
				};
			}
		},

		bookMeet: (state, action: PayloadAction<IBookMeetAction>) => {
			const { relevantMeetList } = state;
			const { time, params } = action.payload;

			if (relevantMeetList) {
				const indexOfChangingMeet = relevantMeetList.findIndex((i) => i.time === time);
				relevantMeetList[indexOfChangingMeet] = {
					...relevantMeetList[indexOfChangingMeet],
					...params,
					available: false,
				};
			}
		},

		deleteAvailableMeet: (state, action: PayloadAction<string>) => {
			const { relevantMeetList } = state;

			if (relevantMeetList) {
				const timeOfDeletingMeet = action.payload;
				const meetIndex = relevantMeetList.findIndex((i) => i.time === timeOfDeletingMeet);
				relevantMeetList.splice(meetIndex, 1);
			}
		},

		deleteBookedMeet: (state, action: PayloadAction<string>) => {
			const { relevantMeetList } = state;

			if (relevantMeetList) {
				const timeOfDeletingMeet = action.payload;
				const deletingMeetIndex = relevantMeetList.findIndex((i) => i.time === timeOfDeletingMeet);
				const deletedMeet = relevantMeetList[deletingMeetIndex];

				const newAvailableMeet: IAvailableMeet = {
					available: true,
					date: deletedMeet.date,
					time: deletedMeet.time,
					isOnline: deletedMeet.isOnline,
					isOffline: deletedMeet.isOffline,
					timestamp: deletedMeet.timestamp,
				};

				relevantMeetList[deletingMeetIndex] = newAvailableMeet;
			}
		},

		setSelectedDate: (state, action: PayloadAction<string>) => {
			state.selectedDate = action.payload;
		},

		deleteMeetList: (state) => {
			state.relevantMeetList = null;
		},
	},
});

export const {
	setRelevantMeetList,
	setSelectedDate,
	addAvailableMeets,
	addAvailableMeet,
	updateAvailableMeet,
	updateBookedMeet,
	bookMeet,
	deleteAvailableMeet,
	deleteBookedMeet,
	deleteMeetList,
} = logbookSlice.actions;

export default logbookSlice.reducer;
