import { useState } from "react";

import certificateList from "../../../assets/certificates.json";
import icons from "../../../assets/images/icons/sprite.svg";

import Card from "./card/Card";

import styles from "./Certificates.module.scss";

const verticalCardWidth = 179;
const horizontalCardWidth = 363;
const columnGap = 20;

const certificates = certificateList.certificatesList;

const getInitialWrapperTranslate = (typeOfFirstCard: "vertical" | "horizontal") => {
	const countOfLeftCards = (certificates.length / 2) % 2 ? certificates.length / 2 - 1 : certificates.length / 2;

	const countOfEachCardTypeOnLeftSide = countOfLeftCards / 2;

	const widthOfLeftSide =
		countOfEachCardTypeOnLeftSide * horizontalCardWidth +
		countOfEachCardTypeOnLeftSide * verticalCardWidth +
		columnGap * countOfLeftCards;

	if (typeOfFirstCard === "horizontal") {
		return widthOfLeftSide + horizontalCardWidth / 2;
	} else {
		return widthOfLeftSide + verticalCardWidth / 2;
	}
};

interface ISertificate {
	id: number;
	pathToPng: string;
	pathToWebP: string;
	pathToFull: string;
	isVertical: boolean;
}

type TSertificateList = ISertificate[];

function Certificates() {
	const [isAnimation, setIsAnimation] = useState<boolean>(false);
	const [isDisabledButtons, setIsDisabledButtons] = useState<boolean>(false);
	const [sertificateList, setSertificateList] = useState<TSertificateList>(certificates);
	const [clickToSide, setClickToSide] = useState<"prev" | "next" | "">("");
	const [nextCardType, setNextCardType] = useState<"vertical" | "horizontal">("vertical");

	const [sertificatesTranslate, setSertificatesTranslate] = useState<number>(getInitialWrapperTranslate("horizontal"));

	function handleClickOnNextBtn() {
		setIsAnimation(true);
		setSertificatesTranslate(sertificatesTranslate + horizontalCardWidth / 2 + verticalCardWidth / 2 + columnGap);
		setIsDisabledButtons(true);
		setClickToSide("next");
	}

	function handleClickOnPrevBtn() {
		setIsAnimation(true);
		setSertificatesTranslate(sertificatesTranslate - horizontalCardWidth / 2 - verticalCardWidth / 2 - columnGap);
		setIsDisabledButtons(true);
		setClickToSide("prev");
	}

	function handleCertificatesTransitionEnd() {
		const sertificateListCopy = sertificateList.slice();

		if (clickToSide === "prev") {
			const indexLastElement = sertificateListCopy.length - 1;
			sertificateListCopy.unshift(sertificateListCopy[indexLastElement]);
			sertificateListCopy.pop();
		} else if (clickToSide === "next") {
			sertificateListCopy.push(sertificateListCopy[0]);
			sertificateListCopy.shift();
		}

		setSertificateList(sertificateListCopy);
		setIsAnimation(false);
		setIsDisabledButtons(false);
		setSertificatesTranslate(getInitialWrapperTranslate(nextCardType));
		setClickToSide("");
		setNextCardType((prevState) => (prevState === "vertical" ? "horizontal" : "vertical"));
	}

	return (
		<section className={styles.section} id="certificates">
			<h2 className={styles.title}>Сертификаты</h2>
			<div className={styles.slider}>
				<div className={styles.viewport} id="certificatesViewport">
					<div
						className={`${styles.certificates} ${isAnimation ? styles.isAnimationCertificates : ""}`}
						onTransitionEnd={handleCertificatesTransitionEnd}
						style={{ transform: `translate(-${sertificatesTranslate}px)` }}>
						{sertificateList.map((i) => (
							<Card
								pathToPng={i.pathToPng}
								pathToWebp={i.pathToWebP}
								pathToFull={i.pathToFull}
								isVertical={i.isVertical}
								key={i.id}
							/>
						))}
					</div>
				</div>

				<div className={styles.buttons}>
					<div className="container">
						<div className={styles.buttonWrapper}>
							<button className={styles.sliderButton} onClick={handleClickOnPrevBtn} disabled={isDisabledButtons}>
								<svg width="21" height="21">
									<use href={icons + "#arrow2"} />
								</svg>
							</button>
							<button
								className={`${styles.sliderButton} ${styles.sliderButtonNext}`}
								onClick={handleClickOnNextBtn}
								disabled={isDisabledButtons}>
								<svg width="21" height="21">
									<use href={icons + "#arrow2"} />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Certificates;
