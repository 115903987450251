import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { IAllMeets } from "../../../models/meets";
import CurrentDate from "../../common/currentDate/CurrentDate";
import LogBookSelector from "../../../redux/logBook/LogBookSelector";
import MyAccountPageSelector from "../../../redux/myAccountPage/MyAccountPageSelector";
import { setRelevantMeetList } from "../../../redux/logBook/LogBookSlice";

import JournalCalendar from "./journalCalendar/JournalCalendar";
import JournalTable from "./journalTable/JournalTable";

import styles from "./Logbook.module.scss";

function getMeetList(allMeets: IAllMeets, selectedDate: string) {
	if (allMeets[selectedDate]) {
		const meetTimes = Object.keys(allMeets[selectedDate].times);
		return meetTimes.map((time) => allMeets[selectedDate].times[time]);
	}

	return [];
}

function Logbook() {
	const dispatch = useDispatch();
	const { allMeets } = useSelector(MyAccountPageSelector);
	const { selectedDate, relevantMeetList } = useSelector(LogBookSelector);

	useEffect(() => {
		if (allMeets && !relevantMeetList) {
			const meetList = getMeetList(allMeets, selectedDate);
			dispatch(setRelevantMeetList(meetList));
		}
	}, [allMeets]);

	return (
		<section className={styles.section}>
			<div className="container">
				<div className={styles.wrapper}>
					<div className={styles.titleWrapper}>
						<CalendarMonthIcon fontSize="large" color="warning" />
						<h2 className={styles.title}>Журнал</h2>
					</div>

					<CurrentDate className={styles.currentDate} />

					<JournalCalendar isLoading={!relevantMeetList} date={selectedDate} />
					<JournalTable meetList={relevantMeetList} />
				</div>
			</div>
		</section>
	);
}

export default Logbook;
