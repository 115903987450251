import React from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

import styles from "./Fields.module.scss";

interface ITimeField {
	value: string;
	date: string;
	meetDate: string;
	timeList: string[];
	meetTime: string;
	onChange: (e: SelectChangeEvent) => void;
}

function TimeField({ value, date, timeList, meetTime, meetDate, onChange }: ITimeField) {
	return (
		<FormControl error={!value} fullWidth>
			<p className={styles.fieldLabel}>Время слота</p>
			<Select
				className={styles.select}
				labelId="time-select-label"
				id="time"
				value={timeList.length ? value : "loading"}
				onChange={onChange}>
				{!timeList.length && <MenuItem value="loading">Загрузка...</MenuItem>}
				{timeList.length &&
					timeList.map((time) => (
						<MenuItem
							key={time}
							value={time}
							sx={time === meetTime && date === meetDate ? { fontWeight: 600 } : undefined}>
							{time}
						</MenuItem>
					))}
			</Select>
			<FormHelperText>{value ? " " : "выберите время"}</FormHelperText>
		</FormControl>
	);
}

export default TimeField;
