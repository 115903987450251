import { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CountryData } from "react-phone-input-2";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import { IAvailableMeets, IBookedMeet } from "../../../../../../models/meets";
import ErrorAlert from "../../../../../common/errorAlert/ErrorAlert";
import DeleteModal from "../deleteModal/DeleteModal";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import {
	updateBookedMeet as updateBookedMeetFromAccountPage,
	deleteBookedMeet as deleteBookedMeetFromAccountPage,
	bookMeet as bookMeetFromAccountPage,
} from "../../../../../../redux/myAccountPage/MyAccountPageSlice";
import {
	updateBookedMeet as updateBookedMeetFromLogbook,
	deleteBookedMeet as deleteBookedMeetFromLogbook,
	bookMeet as bookMeetFromLogbook,
} from "../../../../../../redux/logBook/LogBookSlice";
import {
	setAvailableMeets,
	setStateSuccessAlert,
	addAvailableMeet as addAvailableMeetToJournalTable,
	deleteAvailableMeet as deleteAvailableMeetFromJournalTable,
} from "../../../../../../redux/journalTable/JournalTableSlice";
import {
	getAllAvailableMeets,
	updateBookedMeet as updateBookedMeetAPI,
	deleteBookedMeet as deleteBookedMeetAPI,
	bookMeet as bookMeetAPI,
} from "../../../../../../db/api/meets";
import JournalTableSelector from "../../../../../../redux/journalTable/JournalTableSelector";
import LogBookSelector from "../../../../../../redux/logBook/LogBookSelector";
import { sortDatesInAscendingOrder } from "../../../../../../utils";
import { maximalZIndexValue } from "../../../../../../helpers/constants";

import SurnameField from "./formFields/SurnameField";
import DateField from "./formFields/DateField";
import TimeField from "./formFields/TimeField";
import TypeField from "./formFields/TypeField";
import PhoneField from "./formFields/PhoneField";
import FirstCallField from "./formFields/FirstCallField";
import MessengerField from "./formFields/MessengerField";
import CommentField from "./formFields/CommentField";

import styles from "./ModalForBookedMeet.module.scss";

interface IModalForBookedMeet {
	meet: IBookedMeet;
	onClose: () => void;
}

function getBookedType(meet: IBookedMeet) {
	return `${meet.isBookedOnline ? "онлайн" : "очно"}`;
}

function getTypeList(meet: IBookedMeet) {
	const types = [];
	if (meet.isOnline) types.push("онлайн");
	if (meet.isOffline) types.push("очно");

	return types;
}

function getCurrentDateList(meets: IAvailableMeets, selectedDate: string) {
	const dateList = Object.keys(meets);
	const indexCurrentDate = dateList.indexOf(selectedDate);

	if (indexCurrentDate === -1) dateList.push(selectedDate);

	return sortDatesInAscendingOrder(dateList);
}

function getCurrentTimeList(meets: IAvailableMeets, selectedDate: string, currentTime?: string) {
	const timeList: string[] = [];

	if (currentTime) timeList.push(currentTime);

	if (meets[selectedDate]) {
		timeList.push(...Object.keys(meets[selectedDate].times));
		timeList.sort();
	}

	return timeList;
}

function ModalForBookedMeet({ meet, onClose }: IModalForBookedMeet) {
	const dispatch = useDispatch();
	const { selectedDate } = useSelector(LogBookSelector);
	const { availableMeets } = useSelector(JournalTableSelector);

	const [isOpenLoadingErrorAlert, setIsOpenLoadingErrorAlert] = useState<boolean>(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<boolean>(false);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isOpenSendingErrorAlert, setIsOpenSendingErrorAlert] = useState<boolean>(false);

	const [surname, setSurname] = useState<string>(meet.surname);

	const [date, setDate] = useState<string>(selectedDate);
	const [dateList, setDateList] = useState<string[]>([]);

	const [time, setTime] = useState<string>(meet.time);
	const [timeList, setTimeList] = useState<string[]>([]);

	const [type, setType] = useState<string>(getBookedType(meet));
	const [typeList, setTypeList] = useState<string[]>(getTypeList(meet));

	const [phone, setPhone] = useState<string>(meet.phone);
	const [isValidPhone, setIsValidPhone] = useState<boolean>(true);

	const [messenger, setMessenger] = useState<string>(meet.messenger);

	const [isFirstCall, setIsFirstCall] = useState<boolean>(meet.firstCall);

	const [comment, setComment] = useState<string>(meet.comment);

	useEffect(() => {
		if (!availableMeets) {
			const loadAvailableMeets = async () => {
				try {
					const availableMeets = await getAllAvailableMeets();
					const currentDateList = getCurrentDateList(availableMeets, selectedDate);
					const currentTimeList = getCurrentTimeList(availableMeets, selectedDate, meet.time);

					dispatch(setAvailableMeets(availableMeets));
					setDateList(currentDateList);
					setTimeList(currentTimeList);
				} catch {
					setIsOpenLoadingErrorAlert(true);
				}
			};

			loadAvailableMeets();
		} else {
			const currentDateList = getCurrentDateList(availableMeets, selectedDate);
			const currentTimeList = getCurrentTimeList(availableMeets, selectedDate, meet.time);

			setDateList(currentDateList);
			setTimeList(currentTimeList);
		}
	}, []);

	const handleChangeDate = (e: SelectChangeEvent) => {
		const currentDate = e.target.value;

		if (availableMeets) {
			const currentTimeList =
				currentDate === selectedDate
					? getCurrentTimeList(availableMeets, currentDate, meet.time)
					: getCurrentTimeList(availableMeets, currentDate);

			setDate(e.target.value);
			setTime("");
			setTimeList(currentTimeList);
			setType("");
			setTypeList([]);
		}
	};

	const handleChangeTime = (e: SelectChangeEvent) => {
		const currentTime = e.target.value;

		if (availableMeets) {
			setTime(e.target.value);
			setType("");

			if (currentTime === meet.time) {
				const typeList = getTypeList(meet);
				setTypeList(typeList);
			} else {
				const currenMeet = availableMeets[date].times[currentTime];
				const currentTypeList: string[] = [];

				if (currenMeet.isOnline) currentTypeList.push("онлайн");
				if (currenMeet.isOffline) currentTypeList.push("очно");

				setTypeList(currentTypeList);
			}
		}
	};

	const handleChangePhone = (
		value: string,
		country: CountryData,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string,
	) => {
		setPhone(formattedValue);
		setIsValidPhone(formattedValue.length === country.format.length);
	};

	const handleOpenDeleteModal = () => {
		setIsOpenDeleteModal(true);
	};

	const isEditedForm = () => {
		return (
			surname !== meet.surname ||
			date !== selectedDate ||
			time !== meet.time ||
			type !== getBookedType(meet) ||
			phone !== meet.phone ||
			isFirstCall !== meet.firstCall ||
			messenger !== meet.messenger ||
			comment !== meet.comment
		);
	};

	const isValidForm = () => {
		return !!date && !!time && !!type && isValidPhone;
	};

	const handleClickOnSaveButton = () => {
		setIsOpenConfirmationModal(true);
	};

	const handleClickOnSubmitButton = async () => {
		setIsSending(true);

		const newParams = {
			comment,
			firstCall: isFirstCall,
			messenger,
			phone,
			surname,
			isBookedOnline: type === "онлайн",
			isBookedOffline: type === "очно",
		};

		try {
			if (selectedDate === date && meet.time === time) {
				await updateBookedMeetAPI(date, time, newParams);

				dispatch(updateBookedMeetFromAccountPage({ date, time, params: newParams }));
				dispatch(updateBookedMeetFromLogbook({ time, params: newParams }));
				dispatch(setStateSuccessAlert({ isOpen: true, type: "change" }));
			}

			if (meet.time !== time) {
				const isBookedOnline = type === "онлайн";
				const isBookedOffline = type === "очно";

				await Promise.all([
					deleteBookedMeetAPI(selectedDate, meet.time),
					bookMeetAPI(date, time, phone, surname, isBookedOnline, isBookedOffline, messenger, comment),
				]);

				dispatch(bookMeetFromAccountPage({ date, time, params: newParams }));
				dispatch(deleteBookedMeetFromAccountPage({ date: selectedDate, time: meet.time }));

				if (selectedDate === date) dispatch(bookMeetFromLogbook({ time, params: newParams }));
				dispatch(deleteBookedMeetFromLogbook(meet.time));

				dispatch(
					addAvailableMeetToJournalTable({
						date: selectedDate,
						time: meet.time,
						isOnline: meet.isOnline,
						isOffline: meet.isOffline,
					}),
				);
				dispatch(deleteAvailableMeetFromJournalTable({ date, time }));
				dispatch(setStateSuccessAlert({ isOpen: true, type: "change" }));
			}

			onClose();
		} catch (e) {
			setIsOpenSendingErrorAlert(true);
		}

		setIsSending(false);
	};

	return (
		<>
			<Modal disableAutoFocus open={true} onClose={onClose}>
				<Box className={styles.box} sx={{ boxShadow: 24 }}>
					<div className={styles.innerBox}>
						<IconButton className={styles.closeButton} onClick={onClose}>
							<CloseIcon />
						</IconButton>
						<Typography id="modal-modal-title" fontSize="25px" component="h2">
							Просмотр и редактирование слота <br /> от <b>{selectedDate}</b> на <b>{meet.time}</b>
						</Typography>
						<form className={styles.form}>
							<ul className={styles.formList}>
								<li>
									<SurnameField
										value={surname}
										onChange={(e: ChangeEvent<HTMLInputElement>) => setSurname(e.target.value)}
									/>
								</li>

								<li>
									<DateField value={date} dateList={dateList} meetDate={meet.date} onChange={handleChangeDate} />
								</li>

								<li>
									<TimeField
										value={time}
										date={date}
										meetDate={meet.date}
										timeList={timeList}
										meetTime={meet.time}
										onChange={handleChangeTime}
									/>
								</li>

								<li>
									<TypeField
										value={type}
										time={time}
										date={date}
										meetDate={meet.date}
										meetTime={meet.time}
										typeList={typeList}
										bookedMeetType={getBookedType(meet)}
										onChange={(e: SelectChangeEvent) => setType(e.target.value)}
									/>
								</li>

								<li>
									<PhoneField value={phone} isValidPhone={isValidPhone} onChange={handleChangePhone} />
								</li>

								<li className={styles.firstCallItem}>
									<FirstCallField
										isChecked={isFirstCall}
										onChange={(e: ChangeEvent<HTMLInputElement>) => setIsFirstCall(e.target.checked)}
									/>
								</li>

								<li>
									<MessengerField
										value={messenger}
										meetMessenger={meet.messenger}
										onChange={(e: SelectChangeEvent) => setMessenger(e.target.value)}
									/>
								</li>

								<li>
									<CommentField
										value={comment}
										onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
									/>
								</li>
							</ul>
						</form>

						<div className={styles.buttonWrapper}>
							<Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleOpenDeleteModal}>
								Удалить
							</Button>
							<div className={styles.saveCancelButtonWrapper}>
								<Button onClick={onClose} variant="outlined" color="inherit" startIcon={<CloseIcon />}>
									Отмена
								</Button>
								<Button
									onClick={handleClickOnSaveButton}
									disabled={!isValidForm() || !isEditedForm()}
									variant="contained"
									color="warning"
									startIcon={<SaveIcon />}>
									Сохранить
								</Button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>

			<DeleteModal
				date={date}
				time={time}
				isAvailableTime={meet.available}
				isOpen={isOpenDeleteModal}
				onClose={() => setIsOpenDeleteModal(false)}
				onDelete={onClose}
			/>

			<ConfirmationModal
				isOpen={isOpenConfirmationModal}
				onClose={() => setIsOpenConfirmationModal(false)}
				onSave={handleClickOnSubmitButton}
			/>

			{isOpenLoadingErrorAlert && (
				<ErrorAlert
					isOpen={isOpenLoadingErrorAlert}
					onClose={() => setIsOpenLoadingErrorAlert(false)}
					title={"Ошибка Загрузки!"}
					body={"Ошибка загрузки данных. Обновите страницу или попробуйте позже."}
				/>
			)}

			{isOpenSendingErrorAlert && (
				<ErrorAlert
					isOpen={isOpenLoadingErrorAlert}
					onClose={() => setIsOpenSendingErrorAlert(false)}
					title={"Ошибка!"}
					body={
						<>
							Произошла ошибка во время сохранения изменений. <br /> Обновите страницу или попробуйте позже.
						</>
					}
				/>
			)}

			{isSending || !availableMeets ? (
				<Backdrop
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
					}}
					open={isSending || !availableMeets}>
					<CircularProgress color="warning" thickness={5} size={55} />
				</Backdrop>
			) : undefined}
		</>
	);
}

export default ModalForBookedMeet;
