import React from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

import styles from "./Fields.module.scss";

interface ITypeField {
	value: string;
	time: string;
	date: string;
	meetDate: string;
	meetTime: string;
	typeList: string[];
	bookedMeetType: string;
	onChange: (e: SelectChangeEvent) => void;
}

function TypeField({ value, time, date, meetDate, meetTime, typeList, bookedMeetType, onChange }: ITypeField) {
	return (
		<FormControl error={!value} fullWidth>
			<p className={styles.fieldLabel}>Тип слота</p>
			<Select className={styles.select} labelId="type-select-label" id="type" value={value} onChange={onChange}>
				{!time && <MenuItem value="">Выберите время</MenuItem>}
				{typeList.map((i) => (
					<MenuItem
						key={i}
						value={i}
						sx={i === bookedMeetType && date === meetDate && time === meetTime ? { fontWeight: 600 } : undefined}>
						{i}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{value ? " " : "выберите тип"}</FormHelperText>
		</FormControl>
	);
}

export default TypeField;
