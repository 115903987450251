import { useEffect } from "react";

import AboutMe from "../components/mainPage/aboutMe/AboutMe";
import Certificates from "../components/mainPage/certificates/Certificates";
import Footer from "../components/mainPage/footer/Footer";
import Greeting from "../components/mainPage/greeting/Greeting";
import Header from "../components/mainPage/header/Header";
import LeaveRequest from "../components/mainPage/leaveRequest/LeaveRequest";
import Services from "../components/mainPage/services/Services";
import ShouldContact from "../components/mainPage/shouldContact/ShouldContact";
import Slogan from "../components/mainPage/slogan/Slogan";
import Reviews from "../components/mainPage/reviews/Reviews";
import Contacts from "../components/mainPage/contacts/Contacts";

function MainPage() {
	const intervalData = {
		thirteenMilliseconds: 13,
		fourteenMilliseconds: 14,
	};

	const textContentData = {
		lowerTextContentLimit: 3500,
		higherTextContentLimit: 7500,
		lowerTextContentStepValue: 10,
		higherTextContentStepValue: 20,
	};

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && entry.target.id === "training-hours" && entry.target.textContent === "0") {
					const element = entry.target;

					const trainingInterval = setInterval(() => {
						const { textContent } = element;

						if (typeof textContent === "string" && +textContent < textContentData.lowerTextContentLimit) {
							element.textContent = String(+textContent + textContentData.lowerTextContentStepValue);
						} else {
							clearTimeout(trainingInterval);
						}
					}, intervalData.fourteenMilliseconds);
				}

				if (entry.isIntersecting && entry.target.id === "counseling-hours" && entry.target.textContent === "0") {
					const element = entry.target;

					const trainingInterval = setInterval(() => {
						const { textContent } = element;

						if (typeof textContent === "string" && +textContent < textContentData.higherTextContentLimit) {
							entry.target.innerHTML = String(+entry.target.innerHTML + textContentData.higherTextContentStepValue);
						} else {
							clearTimeout(trainingInterval);
						}
					}, intervalData.thirteenMilliseconds);
				}
			});
		});

		const trainingHours = document.querySelector("#training-hours");
		const counselingHours = document.querySelector("#counseling-hours");

		if (trainingHours && counselingHours) {
			observer.observe(trainingHours);
			observer.observe(counselingHours);
		}
	});

	return (
		<>
			<Header />
			<main>
				<Greeting />
				<AboutMe />
				<Slogan />
				<Services />
				<ShouldContact />
				<Reviews />
				<Certificates />
				<Contacts />
				<LeaveRequest />
			</main>
			<Footer />
		</>
	);
}

export default MainPage;
