import React from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./Fields.module.scss";

const messengerList = ["WhatsApp", "Telegram", "Viber"];

interface IMessengerField {
	value: string;
	meetMessenger: string;
	onChange: (e: SelectChangeEvent) => void;
}

function MessengerField({ value, meetMessenger, onChange }: IMessengerField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Мессенджер для связи</p>
			<Select
				className={styles.select}
				labelId="messenger-select-label"
				id="messenger"
				value={value}
				onChange={onChange}>
				{messengerList.map((i) => (
					<MenuItem key={i} value={i} sx={i === meetMessenger ? { fontWeight: 600 } : undefined}>
						{i}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default MessengerField;
