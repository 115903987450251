import { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

import { IAllMeets } from "../../../../../models/meets";
import { getStringDateFromDayjs } from "../../../../../utils";

function MarkedDay(props: PickersDayProps<Dayjs> & { allMeets?: IAllMeets }) {
	const { allMeets, day, outsideCurrentMonth, ...other } = props;
	const meets = allMeets || {};
	const dateInLoop = getStringDateFromDayjs(day);

	const isAvailableDay =
		!props.outsideCurrentMonth && Object.keys(meets).includes(dateInLoop) && meets[dateInLoop].available;

	const isBusyDay =
		!props.outsideCurrentMonth && Object.keys(meets).includes(dateInLoop) && !meets[dateInLoop].available;

	const backgroundColor = (isAvailableDay && "#33b679") || (isBusyDay && "#fb6900") || undefined;

	return (
		<Badge
			key={props.day.toString()}
			overlap="circular"
			variant={isAvailableDay || isBusyDay ? "dot" : undefined}
			sx={{
				"& .MuiBadge-badge": {
					backgroundColor,
				},
			}}>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}

export default MarkedDay;
