import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { IAvailableMeet, IBookedMeet, isIAvailableMeet } from "../../../../models/meets";
import { setStateSuccessAlert } from "../../../../redux/journalTable/JournalTableSlice";
import SuccessAlert from "../../../common/successAlert/SuccessAlert";
import JournalTableSelector from "../../../../redux/journalTable/JournalTableSelector";

import EditingModal from "./editingModal/EditingModal";

import styles from "./JournalTable.module.scss";

interface IJournalTable {
	meetList: (IAvailableMeet | IBookedMeet)[] | null;
}
function getTypeString(meet: IAvailableMeet | IBookedMeet) {
	if (isIAvailableMeet(meet)) {
		const types = [];
		if (meet.isOnline) types.push("онлайн");
		if (meet.isOffline) types.push("очно");
		return types.join("/");
	} else {
		return `${meet.isBookedOnline ? "онлайн" : "очно"}`;
	}
}

function JournalTable({ meetList }: IJournalTable) {
	const dispatch = useDispatch();
	const { successAlertState } = useSelector(JournalTableSelector);
	const [isOpenEditingModal, setIsOpenEditingModal] = useState<boolean>(false);
	const [editingMeet, setEditingMeet] = useState<IAvailableMeet | IBookedMeet | null>(null);

	const handleCloseEditingModal = () => {
		setIsOpenEditingModal(false);
	};

	const handleClickOnRow = (meet: IAvailableMeet | IBookedMeet) => {
		setIsOpenEditingModal(true);
		setEditingMeet(meet);
	};

	return (
		<>
			<div className={styles.tableWrapper}>
				<Paper variant="outlined">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Время</TableCell>
								<TableCell align="center">Имя</TableCell>
								<TableCell align="center">Телефон</TableCell>
								<TableCell align="center">1-й звонок</TableCell>
								<TableCell align="center">Тип</TableCell>
								<TableCell align="center">Статус</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{meetList &&
								!!meetList.length &&
								meetList.map((meet) => {
									if (isIAvailableMeet(meet)) {
										return (
											<TableRow key={meet.time} hover sx={{ cursor: "pointer" }} onClick={() => handleClickOnRow(meet)}>
												<TableCell sx={{ fontWeight: "500" }}>{meet.time}</TableCell>
												<TableCell align="center" />
												<TableCell align="center" />
												<TableCell align="center"></TableCell>
												<TableCell align="center">{getTypeString(meet)}</TableCell>
												<TableCell align="center" sx={{ color: "#33b679" }}>
													свободно
												</TableCell>
											</TableRow>
										);
									} else {
										return (
											<TableRow key={meet.time} hover sx={{ cursor: "pointer" }} onClick={() => handleClickOnRow(meet)}>
												<TableCell sx={{ fontWeight: "500" }}>{meet.time}</TableCell>
												<TableCell align="center">{meet.surname}</TableCell>
												<TableCell align="center">{meet.phone}</TableCell>
												<TableCell align="center">
													{meet.firstCall ? (
														<CheckIcon color="success" sx={{ fontSize: 20, verticalAlign: "bottom" }} />
													) : (
														<ClearIcon color="error" sx={{ fontSize: 20, verticalAlign: "bottom" }} />
													)}
												</TableCell>
												<TableCell align="center">{getTypeString(meet)}</TableCell>
												<TableCell align="center" sx={{ color: "#fb6900" }}>
													занято
												</TableCell>
											</TableRow>
										);
									}
								})}
						</TableBody>
					</Table>

					{meetList && !meetList.length && <p style={{ textAlign: "center" }}>НЕТ СЛОТОВ</p>}
					{!meetList && <CircularProgress className={styles.loader} color="warning" />}
				</Paper>
			</div>

			{successAlertState.type === "deletion" && (
				<SuccessAlert
					isOpen={successAlertState.isOpen}
					onClose={() => dispatch(setStateSuccessAlert({ isOpen: false }))}
					title={"Слот Удален!"}
					body={
						<>
							Слот для {successAlertState.isAvailableTime ? "доступной" : "забронированной"} консультации был успешно
							удален!
						</>
					}
				/>
			)}

			{successAlertState.type === "change" && (
				<SuccessAlert
					isOpen={successAlertState.isOpen}
					onClose={() => dispatch(setStateSuccessAlert({ isOpen: false }))}
					title={"Слот изменен!"}
					body={"Слот для консультации был успешно изменен!"}
				/>
			)}

			{isOpenEditingModal && editingMeet && (
				<EditingModal isOpen={isOpenEditingModal} onClose={handleCloseEditingModal} meet={editingMeet} />
			)}
		</>
	);
}

export default JournalTable;
