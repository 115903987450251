import icons from "../../../assets/images/shouldContact/sprite.svg";

import styles from "./ShouldContact.module.scss";

function ShouldContact() {
	return (
		<section className={styles.section}>
			<div className="container">
				<h2 className={styles.title}>Почему стоит обратиться ко мне?</h2>
				<p className={styles.subtitle}>
					В своей работе применяю интегративный подход и подбираю индивидуальные методы терапии под каждого клиента
				</p>
				<ul className={styles.advantagesList}>
					<li className={styles.advantageItem}>
						<svg className={styles.advantageImage} width="60" height="80">
							<use href={icons + "#diploma"} />
						</svg>
						<p className={styles.advantageText}>Квалифицированная психологическая помощь и полная анонимность</p>
					</li>

					<li className={styles.advantageItem}>
						<svg className={styles.advantageImage} width="75" height="80">
							<use href={icons + "#cup"} />
						</svg>
						<p className={styles.advantageText}>Более 13 лет консультирования по самым разным вопросам</p>
					</li>

					<li className={styles.advantageItem}>
						<svg className={styles.advantageImage} width="102" height="80">
							<use href={icons + "#handshake"} />
						</svg>
						<p className={styles.advantageText}>Сотни счастливых клиентов и восстановленных семей</p>
					</li>
				</ul>
			</div>
		</section>
	);
}

export default ShouldContact;
