import React, { useMemo } from "react";
import { FormControl } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { TimeValidationError } from "@mui/x-date-pickers/models";
import dayjs, { Dayjs } from "dayjs";

import { timeConstants } from "../../../../../../helpers/constants";

import styles from "./Fields.module.scss";

interface IStartTimeField {
	value: Dayjs | null;
	error: TimeValidationError | null;
	slotInterval: string;
	onChange: (newValue: Dayjs | null) => void;
	onError: (error: TimeValidationError) => void;
}

function StartTimeField({ value, error, slotInterval, onChange, onError }: IStartTimeField) {
	const errorMessage = useMemo(() => {
		if (error === "invalidDate") {
			return "введите корректное время";
		} else if (error === "maxTime") {
			return "указано недопустимое время";
		} else if (!error) {
			return " ";
		}
	}, [error]);

	const maxStartTime = useMemo(() => {
		return dayjs().set("hour", timeConstants.twentyThreeHours).endOf("hour").subtract(+slotInterval, "minutes");
	}, [slotInterval]);

	return (
		<FormControl className={styles.timeControl} fullWidth>
			<p className={styles.fieldLabel}>Время (с)</p>
			<DesktopTimePicker
				className={styles.picker}
				value={value}
				onChange={onChange}
				onError={onError}
				slotProps={{
					textField: {
						helperText: errorMessage,
					},
				}}
				maxTime={maxStartTime}
			/>
		</FormControl>
	);
}

export default StartTimeField;
