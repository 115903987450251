import React from "react";
import dayjs from "dayjs";

import styles from "./CurrentDate.module.scss";

function getMonthName(monthIndex: number) {
	const monthsArray = [
		"января",
		"февраля",
		"марта",
		"апреля",
		"мая",
		"июня",
		"июля",
		"августа",
		"сентября",
		"октября",
		"ноября",
		"декабря",
	];

	return monthsArray[monthIndex];
}

function CurrentDate({ className }: { className?: string }) {
	return (
		<div className={className ? className : ""}>
			<p className={styles.date}>Сегодня: {`${dayjs().date()} ${getMonthName(dayjs().month())} ${dayjs().year()}`}</p>
		</div>
	);
}

export default CurrentDate;
