import { FormControl, FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/material.css";

import styles from "./Fields.module.scss";

function PhoneField() {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Номер телефона</p>
			<PhoneInput
				country={"by"}
				placeholder={"Номер телефона"}
				localization={ru}
				specialLabel={""}
				disabled
				preferredCountries={["by", "ru", "kz"]}
				enableSearch={true}
				inputClass={styles.phoneInput}
			/>
			<FormHelperText> </FormHelperText>
		</FormControl>
	);
}

export default PhoneField;
