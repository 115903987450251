import React, { useState, MouseEvent } from "react";

import photo_png from "../../../assets/images/photos/photo2.png";
import photo_webp from "../../../assets/images/photos/photo2.webp";

import styles from "./AboutMe.module.scss";

function AboutMe() {
	const [isOpenAdditionalEducationList, setIsOpenAdditionalEducationList] = useState<boolean>(false);

	const [isOpenIndividualCounselingList, setIsOpenIndividualCounselingList] = useState<boolean>(false);

	const [isOpenFamilyCounselingList, setIsOpenFamilyCounselingList] = useState<boolean>(false);
	const [isOpenCoachingList, setIsOpenCoachingList] = useState<boolean>(false);

	const handleClickOnMoreButton = (e: MouseEvent<HTMLButtonElement>) => {
		const button = e.currentTarget;
		const { id: buttonId } = button;

		const setLists: { [index: string]: React.Dispatch<React.SetStateAction<boolean>> } = {
			additionalEducationBtn: setIsOpenAdditionalEducationList,
			individualCounselingBtn: setIsOpenIndividualCounselingList,
			familyCounselingBtn: setIsOpenFamilyCounselingList,
			coachingBtn: setIsOpenCoachingList,
		};

		const set = setLists[buttonId];

		set((prevState) => {
			if (prevState) {
				button.textContent = buttonId === "additionalEducationBtn" ? "Показать все" : "Подробнее";
			} else {
				button.textContent = "Скрыть";
			}

			return !prevState;
		});
	};

	return (
		<section className={styles.section} id="aboutMe">
			<div className="container">
				<h2 className={styles.title}>Обо мне</h2>
				<div className={styles.wrapper}>
					<div className={styles.infoWrapper}>
						<picture>
							<source type="image/webp" srcSet={photo_webp} />
							<img className={styles.photo} src={photo_png} alt="photoElement" />
						</picture>
						<div className={styles.cardWrapper}>
							<div className={styles.card}>
								<p className={styles.cardNumber} id="training-hours">
									0
								</p>
								<p className={styles.cardNumberInfo}>часов обучения</p>
							</div>
							<div className={styles.card}>
								<p className={styles.cardNumber} id="counseling-hours">
									0
								</p>
								<p className={styles.cardNumberInfo}>часов консультирования</p>
							</div>
						</div>
					</div>

					<div>
						<p className={styles.firstParagraph}>
							Член Белорусской ассоциации психотерапевтов. <br />
							Дипломированный психолог, коуч, энергопрактик, гипнолог. <br />
							Практикую с 2010 г.
						</p>

						<ul className={styles.typeList}>
							<li>Психоаналитическая терапия</li>
							<li>Когнитивно-поведенческая терапия</li>
							<li>Гипнотерапия</li>
							<li>Эмоционально-образная терапия</li>
							<li>Арт-терапия, МАК</li>
						</ul>

						<div>
							<h3 className={styles.educationTitle}>Образование</h3>
							<p className={styles.educationDesc}>
								Белорусская Академия Последипломного Педагогического Образования; <br />
								Специальность: Психология; Квалификация: Психолог, Преподаватель психологии
							</p>
						</div>

						<div>
							<h3 className={styles.additionalEducationTitle}>Дополнительное образование</h3>
							<ul className={styles.additionalEducationList1}>
								<li>Современные психотехнологии: индивидуальное и семейное психологическое консультирование</li>
								<li>Теория и практика песочной терапии</li>
								<li>Психологические технологии МАК</li>
							</ul>
							<div
								className={`${styles.dropDownBlock} ${isOpenAdditionalEducationList ? styles.openDropDownBlock : ""}`}>
								<ul className={styles.additionalEducationList2}>
									<li>Технология ведения тренинговых групп</li>
									<li>Семейные расстановки по Хеллингеру</li>
									<li>ThetaHealing® Basic DNA; Advanced DNA</li>
									<li>
										Гипнотерапия: психокоррекция поведения, техники самопомощи, лечение неврозов, фобий, панических атак
									</li>
									<li>Телесно-ориентированные и танцевально-двигательные практики</li>
									<li>Мастер-класс «Эмоционально-образная терапия»</li>
									<li>
										IV Научно-практическая конференция «Когнитивно-поведенческий подход в консультировании
										и психотерапии. Проблемы и перспективы развития доказательной психотерапии в Беларуси»
									</li>
									<li>Звукотерапия</li>
									<li>Авторский курс «Терапия Души» Евгения Теребенина</li>
									<li>Нейролингвистическое программирование</li>
								</ul>
							</div>

							<button className={styles.moreButton} id="additionalEducationBtn" onClick={handleClickOnMoreButton}>
								Показать все
							</button>
						</div>

						<div>
							<h3 className={styles.queriesTitle}>Запросы, с которыми я работаю</h3>

							<div>
								<p className={styles.queriesSubtitle}>Индивидуальное консультирование</p>

								<div
									className={`${styles.dropDownBlock} ${
										isOpenIndividualCounselingList ? styles.openDropDownBlock : ""
									}`}>
									<ul className={styles.queriesList}>
										<li>Абьюзивные, болезненные отношения, конфликты и взаимные обиды</li>
										<li>Не складывается личная жизнь, не могу выйти замуж/жениться</li>
										<li>Неудовлетворенность в существующих отношениях, конфликты и взаимные обиды</li>
										<li>Постоянная самокритика и чувство вины, боюсь начинать что-то новое</li>
										<li>Не могу забыть бывшего (бывшую)</li>
										<li>Страхи и фобии</li>
										<li>Измена партнера. Развод. Как пережить расставание. Живу в прошлом</li>
										<li>Любовная зависимость от партнера, отношения приносят страдания</li>
										<li>Устранение плохого настроения, чувства одиночества</li>
										<li>Поиск ресурсов для восстановления энергии и сил</li>
										<li>Восстановление желания работать и жить</li>
										<li>Поиск смысла жизни, нахождение целей и путей их достижения</li>
										<li>Развитие уверенности в себе</li>
									</ul>
								</div>

								<button className={styles.moreButton} id="individualCounselingBtn" onClick={handleClickOnMoreButton}>
									Подробнее
								</button>
							</div>

							<div>
								<p className={styles.queriesSubtitle}>Семейное консультирование</p>
								<div
									className={`${styles.dropDownBlock} ${isOpenFamilyCounselingList ? styles.openDropDownBlock : ""}`}>
									<ul className={styles.queriesList}>
										<li>Неудовлетворенность в существующих отношениях, конфликты и взаимные обиды</li>
										<li>Измена партнера, хочу сохранить семью</li>
										<li>Психологическая поддержка бракоразводного процесса</li>
										<li>Супружеские отношения, неудовлетворенность браком, живем как брат с сестрой</li>
										<li>Мне уже за…, но моя мама/папа продолжают меня воспитывать</li>
										<li>Как мягко сепарироваться от родителей и начать жить эмоционально отдельно</li>
										<li>Нахождение взаимопонимания между партнерами</li>
									</ul>
								</div>

								<button className={styles.moreButton} id="familyCounselingBtn" onClick={handleClickOnMoreButton}>
									Подробнее
								</button>
							</div>

							<div>
								<p className={styles.queriesSubtitle}>Коучинг</p>
								<div className={`${styles.dropDownBlock} ${isOpenCoachingList ? styles.openDropDownBlock : ""}`}>
									<ul className={styles.queriesList}>
										<li>Как найти свое предназначение?</li>
										<li>Много интересов и задач, но ничего не успеваю</li>
										<li>Много идей, но нет их реализации</li>
										<li>Постоянный упадок сил и ресурса</li>
										<li>Не хватает уверенности/мотивации двигаться к своим целям</li>
										<li>Непонимание как поступить в ситуации, сделать выбор</li>
										<li>Внутренний эмоциональный дискомфорт</li>
										<li>Страшно начинать новое дело</li>
										<li>Как найти баланс работа - личная жизнь</li>
									</ul>
								</div>

								<button className={styles.moreButton} id="coachingBtn" onClick={handleClickOnMoreButton}>
									Подробнее
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AboutMe;
