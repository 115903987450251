import React, { useState } from "react";

import { List, ListItemButton, ListItemText, Paper } from "@mui/material";

import styles from "./Navigation.module.scss";

interface INavigation {
	className?: string;
	onNavItemClick: (type: "slot" | "day") => void;
}

function Navigation({ className, onNavItemClick }: INavigation) {
	const [selectedIndex, setSelectedIndex] = useState(1);

	const handleListItemClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
		type: "slot" | "day",
	) => {
		setSelectedIndex(index);
		onNavItemClick(type);
	};

	return (
		<Paper className={className ? className : ""} variant="outlined">
			<List className={styles.navList}>
				<ListItemButton
					className={styles.navItem}
					selected={selectedIndex === 1}
					onClick={(event) => handleListItemClick(event, 1, "slot")}>
					<ListItemText primary="Добавить свободный слот" />
				</ListItemButton>

				<ListItemButton
					className={styles.navItem}
					selected={selectedIndex === 2}
					onClick={(event) => handleListItemClick(event, 2, "day")}>
					<ListItemText primary="Добавить рабочий день" />
				</ListItemButton>
			</List>
		</Paper>
	);
}

export default Navigation;
