export const CorrectDeviceTypeList = {
	desktopDevice: "desktop",
	tabletDevice: "tablet",
	mobileDevice: "mobile",
} as const;

export const publicContractDesktop: string =
	"https://drive.google.com/file/d/1dPZcr_eD6WMFPZXMDAj-sQrQYXRHD3pF/view?usp=sharing";//=>ok
export const personalDataProcessingPolicyDesktop: string =
	"https://drive.google.com/file/d/1Pp4L_JyzfkbxYUYHyqXsJRFugCO2JY3P/view?usp=sharing";//=>ok
export const privacyPolicyDesktop: string =
	"https://drive.google.com/file/d/10NWOrpsq-SpCTSfD_DNh5P8uKPBEqFZc/view?usp=sharing";//=>ok
export const paymentTermsDesktop: string =
	"https://drive.google.com/file/d/1vUW9EcitIEuZoK8q7D1heJYb5JOVvLp6/view?usp=sharing";//=>ok

export const publicContractTablet: string =
	"https://drive.google.com/file/d/1dPZcr_eD6WMFPZXMDAj-sQrQYXRHD3pF/view?usp=sharing";//=>ok
export const personalDataProcessingPolicyTablet: string =
	"https://drive.google.com/file/d/1Pp4L_JyzfkbxYUYHyqXsJRFugCO2JY3P/view?usp=sharing";//=>ok
export const privacyPolicyTablet: string =
	"https://drive.google.com/file/d/10NWOrpsq-SpCTSfD_DNh5P8uKPBEqFZc/view?usp=sharing";//=>ok
export const paymentTermsTablet: string =
	"https://drive.google.com/file/d/1vUW9EcitIEuZoK8q7D1heJYb5JOVvLp6/view?usp=sharing";//=>ok

export const publicContractMobile: string =
	"https://drive.google.com/file/d/1gMqS7ErGvDy-pzgptWx63OHWpK2hK6SC/view?usp=sharing";//=>ok
export const personalDataProcessingPolicyMobile: string =
	"https://drive.google.com/file/d/1Soggaqveasf6htguyBtrlfAJBPhGSctz/view?usp=sharing";//=>ok
export const privacyPolicyMobile: string =
	"https://drive.google.com/file/d/1U72kd_JdFDw6gaMUuMrbN89BJEqNo23X/view?usp=sharing";//=>ok
export const paymentTermsMobile: string =
	"https://drive.google.com/file/d/1U5ghIIuOzBrOyXKHxstO49C99k8zQaGZ/view?usp=sharing";//=>ok
