import { useEffect, useState } from "react";

import icons from "../../../assets/images/icons/sprite.svg";

import { TCorrectDeviceTypeList } from "../../../types/components/Footer";
import { handleClickOnNavigationLink, scrollToTopOfPage } from "../../../utils";
import { developLink } from "../../../helpers/constants";
import {
	CorrectDeviceTypeList,
	paymentTermsDesktop,
	paymentTermsMobile,
	paymentTermsTablet,
	personalDataProcessingPolicyDesktop,
	personalDataProcessingPolicyMobile,
	personalDataProcessingPolicyTablet,
	privacyPolicyDesktop,
	privacyPolicyMobile,
	privacyPolicyTablet,
	publicContractDesktop,
	publicContractMobile,
	publicContractTablet,
} from "../../../helpers/footerHelpers";

import styles from "./Footer.module.scss";

function Footer() {
	const [correctDeviceType, setCorrectDeviceType] = useState<TCorrectDeviceTypeList>(
		CorrectDeviceTypeList.desktopDevice,
	);

	const tabletDeviceWidth: number = 770;
	const mobileDeviceWidth: number = 430;

	useEffect(() => {
		if ((document.documentElement.clientWidth as number) < tabletDeviceWidth) {
			setCorrectDeviceType(CorrectDeviceTypeList.tabletDevice);
		}

		if ((document.documentElement.clientWidth as number) < mobileDeviceWidth) {
			setCorrectDeviceType(CorrectDeviceTypeList.mobileDevice);
		}
	}, []);

	function getCorrectPublicContractLink(): string {
		if (correctDeviceType === CorrectDeviceTypeList.desktopDevice) {
			return publicContractDesktop;
		}

		return correctDeviceType === CorrectDeviceTypeList.tabletDevice ? publicContractTablet : publicContractMobile;
	}

	function getCorrectPersonalDataPolicyLink(): string {
		if (correctDeviceType === CorrectDeviceTypeList.desktopDevice) {
			return personalDataProcessingPolicyDesktop;
		}

		return correctDeviceType === CorrectDeviceTypeList.tabletDevice
			? personalDataProcessingPolicyTablet
			: personalDataProcessingPolicyMobile;
	}

	function getCorrectPrivacyPolicyLink(): string {
		if (correctDeviceType === CorrectDeviceTypeList.desktopDevice) {
			return privacyPolicyDesktop;
		}

		return correctDeviceType === CorrectDeviceTypeList.tabletDevice ? privacyPolicyTablet : privacyPolicyMobile;
	}

	function getCorrectPaymentTermsLink(): string {
		if (correctDeviceType === CorrectDeviceTypeList.desktopDevice) {
			return paymentTermsDesktop;
		}

		return correctDeviceType === CorrectDeviceTypeList.tabletDevice ? paymentTermsTablet : paymentTermsMobile;
	}

	return (
		<footer className={styles.footer}>
			<div className="container">
				<div className={styles.wrapper}>
					<div className={styles.infoWrapper}>
						<div className={styles.logoWrapper}>
							<svg width="50" height="50">
								<use href={icons + "#logo"} />
							</svg>
							<p className={styles.copyright}>Copyright | 2023</p>
							<ul className={styles.socialList}>
								<li>
									<a
										className={styles.socialLink}
										href="https://t.me/+_VQYzmHrI_Y1MTky"
										target="_blank"
										rel="noreferrer">
										<svg width="25" height="25">
											<use href={icons + "#telegram"} />
										</svg>
									</a>
								</li>
								<li>
									<a
										className={styles.socialLink}
										href="https://www.instagram.com/tihonovich.nataliya/"
										target="_blank"
										rel="noreferrer">
										<svg width="24" height="24">
											<use href={icons + "#instagram"} />
										</svg>
									</a>
								</li>
								<li>
									<a	
										className={styles.socialLink}
										href="viber://add?number=+375296991110"
										target="_blank"
										rel="noreferrer">
										<svg width="30" height="30">
											<use href={icons + "#viber"} />
										</svg>
									</a>
								</li>
								<li>
									<a
										className={styles.socialLink}
										href="https://api.whatsapp.com/send?phone=375296991110"
										target="_blank"
										rel="noreferrer"
										>
										<svg width="25" height="25">
											<use href={icons + "#whatsap"} />
										</svg>
									</a>
								</li>
							</ul>

							<p className={styles.companyInformation}>
								Индивидуальный предприниматель Тихонович Наталья Владимировна, УНП 191850510.
								<br />
								Свидетельство о государственной регистрации, выданное Минским городским исполнительным комитетом
								28.02.2013г. № 0370565.
								<br />
								Республика Беларусь, 220007, г. Минск, ул.&nbsp;Могилевская, д. 16, кв. 408
							</p>
						</div>

						<div className={styles.navWrapper}>
							<p className={styles.navTitle}>Меню</p>
							<ul className={styles.navList}>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#aboutMe" onClick={handleClickOnNavigationLink}>
										Обо мне
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#services" onClick={handleClickOnNavigationLink}>
										Услуги
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#reviews" onClick={handleClickOnNavigationLink}>
										Отзывы
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#certificates" onClick={handleClickOnNavigationLink}>
										Сертификаты
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#contacts" onClick={handleClickOnNavigationLink}>
										Контакты
									</a>
								</li>
							</ul>
						</div>

						<div className={styles.contactsWrapper}>
							<p className={styles.contactTitle}>Контакты</p>
							<ul className={styles.contactList}>
								<li className={styles.contactItem}>
									<a
										className={styles.contactLink}
										href="https://yandex.by/maps/-/CDeoALpa"
										target="_blank"
										rel="noopener noreferrer">
										<svg width="21" height="21">
											<use href={icons + "#location"} />
										</svg>
										г. Минск, ул. Платонова 20б, к. 4, каб. 607
									</a>
								</li>

								<li className={styles.contactItem}>
									<a className={styles.contactLink} href="tel:+375296991110">
										<svg width="21" height="21">
											<use href={icons + "#phone"} />
										</svg>
										+375 (29) 699-11-10
									</a>
								</li>

								<li className={styles.contactItem}>
									<a className={styles.contactLink} href="mailto:tihonovich.psy@gmail.com">
										<svg width="21" height="19">
											<use href={icons + "#envelope"} />
										</svg>
										tihonovich.psy@gmail.com
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className={styles.additionalWrapper}>
						<button className={styles.upButton} onClick={scrollToTopOfPage}>
							<svg width="24" height="24">
								<use href={icons + "#arrow"} />
							</svg>
						</button>
						<div className={styles.additionalLinks}>
							{/* <NavLink className={styles.additionalLink} to="/">
								Политика конфиденциальности
							</NavLink> */}

							<a
								href={getCorrectPrivacyPolicyLink()}
								target="_blank"
								rel="noopener noreferrer"
								className={styles.additionalLink}>
								Политика конфиденциальности
							</a>

							{/* <NavLink className={styles.additionalLink} to="/">
								Политика обработки персональных данных
							</NavLink> */}
							<a
								href={getCorrectPersonalDataPolicyLink()}
								target="_blank"
								rel="noopener noreferrer"
								className={styles.additionalLink}>
								Политика обработки персональных данных
							</a>

							{/* <NavLink className={styles.additionalLink} to="/">
								Договор публичной оферты
							</NavLink> */}
							<a
								href={getCorrectPublicContractLink()}
								target="_blank"
								rel="noopener noreferrer"
								className={styles.additionalLink}>
								Договор публичной оферты
							</a>

							{/* <NavLink className={styles.additionalLink} to="/">
								Условия и способы оплаты услуг
							</NavLink> */}
							<a
								href={getCorrectPaymentTermsLink()}
								target="_blank"
								rel="noopener noreferrer"
								className={styles.additionalLink}>
								Условия и способы оплаты услуг
							</a>

							<a className={styles.additionalLink} href={developLink}>
								Разработка сайта
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
