import React from "react";

import { FormControl } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./Fields.module.scss";

interface IIntervalField {
	value: string;
	onChange: (e: SelectChangeEvent) => void;
}

function IntervalField({ value, onChange }: IIntervalField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Интервал между слотами</p>
			<Select className={styles.select} value={value} onChange={onChange}>
				<MenuItem value={30}>30 минут</MenuItem>
				<MenuItem value={45}>45 минут</MenuItem>
				<MenuItem value={60}>60 минут</MenuItem>
				<MenuItem value={75}>1 час 15 минут</MenuItem>
				<MenuItem value={90}>1 час 30 минут</MenuItem>
			</Select>
		</FormControl>
	);
}

export default IntervalField;
