import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import MyAccountPageSelector from "../../../redux/myAccountPage/MyAccountPageSelector";
import CurrentDate from "../../../components/common/currentDate/CurrentDate";
import ErrorAlert from "../../../components/common/errorAlert/ErrorAlert";
import { setAllEmails } from "../../../redux/myAccountPage/MyAccountPageSlice";
import { deleteAllEmails, getAllEmails } from "../../../db/api/email";

import styles from "./Notifications.module.scss";

function Notifications() {
	const dispatch = useDispatch();
	const { emailList, countEmails } = useSelector(MyAccountPageSelector).emails;
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);

	useEffect(() => {
		if (!emailList && countEmails !== 0) {
			const loadingEmails = async () => {
				try {
					const allEmails = await getAllEmails();
					deleteAllEmails(allEmails).catch(() => {});
					dispatch(setAllEmails(allEmails));
				} catch {
					setIsOpenErrorAlert(true);
				}
			};

			loadingEmails();
		}
	}, []);

	const handleCloseErrorAlert = () => {
		setIsOpenErrorAlert(false);
	};

	return (
		<section className={styles.section}>
			<div className="container">
				<div className={styles.wrapper}>
					<div className={styles.titleWrapper}>
						<NotificationsNoneIcon fontSize="large" color="warning" />
						<h2 className={styles.title}>Уведомления</h2>
					</div>
					<CurrentDate className={styles.currentDate} />

					{countEmails !== null && countEmails > 0 && (
						<p className={styles.subtitle}>
							<b>У вас новые забронированные слоты ({countEmails})</b>
						</p>
					)}

					{countEmails === 0 && <b className={styles.subtitle2}>Нет новых уведомлений</b>}

					{!!emailList?.length && (
						<Paper className={styles.paper} variant="outlined">
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>№</TableCell>
										<TableCell align="center">Дата</TableCell>
										<TableCell align="center">Время</TableCell>
										<TableCell align="center">Тип</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{emailList.map((i, index) => (
										<TableRow key={`${i.info.date}${i.info.time}`} hover>
											<TableCell sx={{ fontWeight: "500" }}>{index + 1}</TableCell>
											<TableCell align="center" sx={{ fontWeight: "500" }}>
												{i.info.date}
											</TableCell>
											<TableCell align="center" sx={{ fontWeight: "500" }}>
												{i.info.time}
											</TableCell>
											<TableCell align="center" sx={{ fontWeight: "500" }}>
												{i.info.type === "online" ? "онлайн" : "очно"}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					)}

					{!emailList && countEmails !== 0 && <CircularProgress className={styles.circularProgress} color="warning" />}

					<ErrorAlert
						isOpen={isOpenErrorAlert}
						onClose={handleCloseErrorAlert}
						title={"Ошибка Загрузки!"}
						body={"Ошибка загрузки данных. Обновите страницу или попробуйте позже."}
					/>
				</div>
			</div>
		</section>
	);
}

export default Notifications;
