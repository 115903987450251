import { useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { DateValidationError, TimeValidationError } from "@mui/x-date-pickers/models";

import SaveIcon from "@mui/icons-material/Save";

// import { RootState } from "../../../../../redux/store";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import ErrorAlert from "../../../../common/errorAlert/ErrorAlert";
import SuccessAlert from "../../../../common/successAlert/SuccessAlert";
import { createAvailableMeet } from "../../../../../db/api/meets";
import MyAccountPageSelector from "../../../../../redux/myAccountPage/MyAccountPageSelector";
import LogBookSelector from "../../../../../redux/logBook/LogBookSelector";
import { addAvailableMeet as addAvailableMeetToMyAccount } from "../../../../../redux/myAccountPage/MyAccountPageSlice";
import { addAvailableMeet as addAvailableMeetToLogbook } from "../../../../../redux/logBook/LogBookSlice";
import { addAvailableMeet as addAvailableMeetToJournalTable } from "../../../../../redux/journalTable/JournalTableSlice";
import { getStringDateFromDayjs, getStringTimeFromDayjs } from "../../../../../utils";
import { maximalZIndexValue } from "../../../../../helpers/constants";

import DateField from "./fields/DateField";
import TimeField from "./fields/TimeField";
import TypeField from "./fields/TypeField";

import styles from "./Form.module.scss";

function Form() {
	const dispatch = useDispatch();
	const { allMeets } = useSelector(MyAccountPageSelector);
	const { selectedDate } = useSelector(LogBookSelector);

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);
	const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState<boolean>(false);

	const [dateValue, setDateValue] = useState<Dayjs | null>(null);
	const [dateError, setDateError] = useState<DateValidationError | null>(null);

	const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
	const [timeError, setTimeError] = useState<TimeValidationError | null>(null);

	const [isOnline, setIsOnline] = useState<boolean>(false);
	const [isOffline, setIsOffline] = useState<boolean>(false);

	const isValidForm = () => {
		return dateValue && !dateError && timeValue && !timeError && (isOnline || isOffline);
	};

	const handleClickOnSubmitButton = async () => {
		if (dateValue && timeValue) {
			setIsSending(true);

			const date = getStringDateFromDayjs(dateValue);
			const time = getStringTimeFromDayjs(timeValue);

			try {
				await createAvailableMeet(date, time, isOnline, isOffline);
				setIsOpenModal(false);
				setIsOpenSuccessAlert(true);

				const newAvailableMeet = { date, time, isOnline, isOffline };
				dispatch(addAvailableMeetToMyAccount(newAvailableMeet));
				if (date === selectedDate) {
					dispatch(addAvailableMeetToLogbook(newAvailableMeet));
				}
				dispatch(addAvailableMeetToJournalTable(newAvailableMeet));
			} catch {
				setIsOpenErrorAlert(true);
			}

			setIsSending(false);
		}
	};

	return (
		<>
			<form>
				<ul className={styles.formList}>
					<li>
						<DateField
							value={dateValue}
							error={dateError}
							onChange={(newValue: dayjs.Dayjs | null) => setDateValue(newValue)}
							onError={(newError) => setDateError(newError)}
						/>
					</li>

					<li>
						<TimeField
							value={timeValue}
							dateValue={dateValue}
							allMeets={allMeets}
							error={timeError}
							onChange={(newValue: dayjs.Dayjs | null) => setTimeValue(newValue)}
							onError={(newError) => setTimeError(newError)}
						/>
					</li>

					<li>
						<TypeField
							isOnline={isOnline}
							isOffline={isOffline}
							onChangeOnline={(e: ChangeEvent<HTMLInputElement>) => setIsOnline(e.target.checked)}
							onChangeOffline={(e: ChangeEvent<HTMLInputElement>) => setIsOffline(e.target.checked)}
						/>
					</li>

					<li className={styles.buttonItem}>
						<Button
							className={styles.saveButton}
							disabled={!isValidForm()}
							onClick={() => setIsOpenModal(true)}
							variant="contained"
							color="warning"
							startIcon={<SaveIcon />}>
							Сохранить
						</Button>
					</li>
				</ul>
			</form>

			<ConfirmationModal
				isOpen={isOpenModal}
				onSubmit={handleClickOnSubmitButton}
				onClose={() => setIsOpenModal(false)}
				date={dateValue}
				time={timeValue}
			/>

			<ErrorAlert
				isOpen={isOpenErrorAlert}
				onClose={() => setIsOpenErrorAlert(false)}
				title={"Ошибка!"}
				body={
					<>
						Произошла ошибка во время создания слота. <br /> Обновите страницу или попробуйте позже.
					</>
				}
			/>

			<SuccessAlert
				isOpen={isOpenSuccessAlert}
				onClose={() => setIsOpenSuccessAlert(false)}
				title={"Слот создан!"}
				body={"Новый слот был успешно создан!"}
			/>

			{isSending && (
				<Backdrop
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
					}}
					open={isSending}>
					<CircularProgress color="warning" thickness={5} size={55} />
				</Backdrop>
			)}
		</>
	);
}

export default Form;
