import { useState, MouseEvent } from "react";
import { Backdrop } from "@mui/material";

import icons from "../../../assets/images/icons/sprite.svg";

import { handleClickOnNavigationLink, scrollToTopOfPage } from "../../../utils";

import styles from "./Header.module.scss";

function Header() {
	const [isOpenedMenu, setIsOpenedMenu] = useState<boolean>(false);

	function handleClickOnMobileNavigationLink(e: MouseEvent<HTMLAnchorElement>) {
		setIsOpenedMenu(false);
		handleClickOnNavigationLink(e);
	}

	function nandleClickOnMobileLogo() {
		setIsOpenedMenu(false);
		scrollToTopOfPage();
	}

	return (
		<header className={styles.headerWrapper} id="header">
			<div className={styles.header}>
				<div className="container">
					<div className={styles.wrapper}>
						<button className={styles.logo} onClick={scrollToTopOfPage}>
							<svg width="60" height="60">
								<use href={icons + "#logo"} />
							</svg>
						</button>
						<button className={styles.mobileLogo} onClick={nandleClickOnMobileLogo}>
							<svg width="47" height="47">
								<use href={icons + "#logo"} />
							</svg>
						</button>

						<nav className={styles.navigation}>
							<ul className={styles.navList}>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#aboutMe" onClick={handleClickOnNavigationLink}>
										Обо мне
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#services" onClick={handleClickOnNavigationLink}>
										Услуги
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#reviews" onClick={handleClickOnNavigationLink}>
										Отзывы
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#certificates" onClick={handleClickOnNavigationLink}>
										Сертификаты
									</a>
								</li>
								<li className={styles.navItem}>
									<a className={styles.navLink} href="#contacts" onClick={handleClickOnNavigationLink}>
										Контакты
									</a>
								</li>
							</ul>
						</nav>

						<div
							className={`${styles.hamburgerMenu} ${isOpenedMenu ? styles.open : ""}`}
							onClick={() => setIsOpenedMenu(!isOpenedMenu)}>
							<span className={styles.menuLine}></span>
							<span className={styles.menuLine}></span>
							<span className={styles.menuLine}></span>
						</div>
					</div>
				</div>
			</div>

			<div className={`${styles.mobileNav} ${isOpenedMenu ? styles.opened : ""}`}>
				<ul className={styles.navList}>
					<li className={styles.navItem}>
						<a className={styles.navLink} href="#aboutMe" onClick={handleClickOnMobileNavigationLink}>
							Обо мне
						</a>
					</li>
					<li className={styles.navItem}>
						<a className={styles.navLink} href="#services" onClick={handleClickOnMobileNavigationLink}>
							Услуги
						</a>
					</li>
					<li className={styles.navItem}>
						<a className={styles.navLink} href="#reviews" onClick={handleClickOnMobileNavigationLink}>
							Отзывы
						</a>
					</li>
					<li className={styles.navItem}>
						<a className={styles.navLink} href="#certificates" onClick={handleClickOnMobileNavigationLink}>
							Сертификаты
						</a>
					</li>
					<li className={styles.navItem}>
						<a className={styles.navLink} href="#contacts" onClick={handleClickOnMobileNavigationLink}>
							Контакты
						</a>
					</li>
				</ul>
			</div>
			{isOpenedMenu && <Backdrop open={isOpenedMenu} onClick={() => setIsOpenedMenu(!isOpenedMenu)} />}
		</header>
	);
}

export default Header;
