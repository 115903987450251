import React, { ChangeEvent } from "react";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import styles from "./Fields.module.scss";

interface ISurnameField {
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function SurnameField({ value, onChange }: ISurnameField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Имя клиента</p>
			<TextField className={styles.textField} value={value} onChange={onChange} helperText={" "} />
		</FormControl>
	);
}

export default SurnameField;
