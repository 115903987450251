import React, { ChangeEvent } from "react";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
// import { Checkbox } from "@mui/material";

import styles from "./Fields.module.scss";

interface ITypeField {
	isOnline: boolean;
	isOffline: boolean;
	onChangeOnline: (e: ChangeEvent<HTMLInputElement>) => void;
	onChangeOffline: (e: ChangeEvent<HTMLInputElement>) => void;
}

function TypeField({ isOnline, isOffline, onChangeOnline, onChangeOffline }: ITypeField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Тип слотов</p>
			<div className={styles.checkboxWrapper}>
				<FormControlLabel
					control={<Checkbox onChange={onChangeOnline} color="warning" checked={isOnline} />}
					label="онлайн"
				/>
				<FormControlLabel
					control={<Checkbox onChange={onChangeOffline} color="warning" checked={isOffline} />}
					label="очные"
				/>
			</div>
		</FormControl>
	);
}

export default TypeField;
