import { handleClickOnNavigationLink } from "../../../utils";

import photo_png from "../../../assets/images/photos/photo1.png";
import photo_webp from "../../../assets/images/photos/photo1.webp";

import styles from "./Greeting.module.scss";

function Greeting() {
	return (
		<section className={styles.section}>
			<div className="container">
				<div className={styles.wrapper}>
					<h1 className={styles.title}>Психолог Тихонович Наталья</h1>
					<div className={styles.photoWrapper}>
						<div className={styles.animatedBg}></div>
						<picture className={styles.picture}>
							<source type="image/webp" srcSet={photo_webp} />
							<img className={styles.photo} src={photo_png} alt="photoElement" />
						</picture>
					</div>
					<div className={styles.textWrapper}>
						<p className={styles.headline}>психолог</p>
						<p className={styles.name}>Тихонович Наталья</p>
						<p className={styles.firstSlogan}>Помогаю решать вопросы в различных жизненных сферах.</p>
						<p className={styles.secondSlogan}>
							Делюсь инструментами, с которыми вы сможете восстановить силы и найти ресурсы для достижения поставленных
							целей.
						</p>
						<p className={styles.thirdSlogan}>Вместе мы найдем выход из любой ситуации!</p>
						<a className={styles.toBookLink} href="#leaveRequest" onClick={handleClickOnNavigationLink}>
							Записаться
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Greeting;
