import React from "react";
import { FormControl, TextField } from "@mui/material";

import styles from "./Fields.module.scss";

function CommentField() {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Комментарий клиента</p>
			<TextField className={styles.textField} id="comment" multiline rows={4} disabled />
		</FormControl>
	);
}

export default CommentField;
