import { getDocs, collection, setDoc, updateDoc, deleteDoc, doc, query, where, deleteField } from "firebase/firestore";

import { IAllMeets, IAvailableMeets, IAvailableMeet, IBookedMeet } from "../../models/meets";
import { getTimestamp } from "../../utils";

import db from "../config";

interface INotStructuredAllMeets {
	[index: string]: IAvailableMeet | IBookedMeet;
}

interface INotStructuredAvailableMeets {
	[index: string]: IAvailableMeet;
}

interface IUpdatedParamsOfBookedMeet {
	[index: string]: string | boolean;
	comment: string;
	firstCall: boolean;
	messenger: string;
	phone: string;
	surname: string;
	isBookedOnline: boolean;
	isBookedOffline: boolean;
}

function structureAllMeetsFromDB(meets: INotStructuredAllMeets) {
	const structuredMeets: IAllMeets = {};

	for (const i in meets) {
		const [date, time] = i.split("|");
		const { available } = meets[i];
		const dateAvailable = structuredMeets[date]?.available || available;

		structuredMeets[date] = {
			available: dateAvailable,

			times: {
				...structuredMeets[date]?.times,
				[time]: {
					...meets[i],
				},
			},
		};
	}

	return structuredMeets;
}

function structureAvailableMeetsFromDB(meets: INotStructuredAvailableMeets) {
	const structuredMeets: IAvailableMeets = {};

	for (const i in meets) {
		const [date, time] = i.split("|");

		structuredMeets[date] = {
			times: {
				...structuredMeets[date]?.times,
				[time]: {
					...meets[i],
				},
			},
		};

		const rr = meets[i];

		structuredMeets[date].times[time] = rr;
	}

	return structuredMeets;
}

function isOldMeet(meet: IAvailableMeet | IBookedMeet, overMonthsOld: number) {
	const now = Date.now();
	const meetDate = new Date(meet.date.split(".").reverse().join("-")).getTime();
	const millisecondsInMonth = 2678400000;

	return !(now - meetDate < millisecondsInMonth * overMonthsOld);
}

async function createAvailableMeet(date: string, time: string, isOnline: boolean, isOffline: boolean) {
	const timestamp = getTimestamp(date, time);

	return await setDoc(doc(db, "meets", `${date}|${time}`), {
		available: true,
		date,
		time,
		isOnline,
		isOffline,
		timestamp,
	});
}

async function getAllMeets() {
	const querySnapshot = await getDocs(collection(db, "meets"));
	const notStructuredMeets: INotStructuredAllMeets = {};
	const deletingMeets: (IAvailableMeet | IBookedMeet)[] = [];

	querySnapshot.forEach((doc: any) => {
		const meet = doc.data() as IAvailableMeet | IBookedMeet;

		if (isOldMeet(meet, 1)) {
			deletingMeets.push(meet);
		} else {
			notStructuredMeets[doc.id] = meet;
		}
	});

	await Promise.all(deletingMeets);

	return structureAllMeetsFromDB(notStructuredMeets);
}

async function getAllAvailableMeets() {
	const q = query(collection(db, "meets"), where("available", "==", true));
	const querySnapshot = await getDocs(q);
	const notStructuredMeets: INotStructuredAvailableMeets = {};

	querySnapshot.forEach((doc: any) => {
		notStructuredMeets[doc.id] = doc.data() as IAvailableMeet;
	});

	return structureAvailableMeetsFromDB(notStructuredMeets);
}

async function getCurrentAvailableMeets() {
	const q = query(collection(db, "meets"), where("available", "==", true), where("timestamp", ">", Date.now()));
	const querySnapshot = await getDocs(q);
	const notStructuredMeets: INotStructuredAvailableMeets = {};

	querySnapshot.forEach((doc: any) => {
		notStructuredMeets[doc.id] = doc.data() as IAvailableMeet;
	});

	return structureAvailableMeetsFromDB(notStructuredMeets);
}

async function bookMeet(
	date: string,
	time: string,
	phone: string,
	surname: string,
	isBookedOnline: boolean,
	isBookedOffline: boolean,
	messenger: string,
	comment: string,
) {
	return await updateDoc(doc(db, "meets", `${date}|${time}`), {
		available: false,
		firstCall: false,
		phone,
		surname,
		isBookedOnline,
		isBookedOffline,
		messenger,
		comment,
	});
}

async function updateAvailableMeet(date: string, time: string, isOnline: boolean, isOffline: boolean) {
	return await updateDoc(doc(db, "meets", `${date}|${time}`), { isOnline, isOffline });
}

async function updateBookedMeet(date: string, time: string, params: IUpdatedParamsOfBookedMeet) {
	return await updateDoc(doc(db, "meets", `${date}|${time}`), params);
}

async function deleteAvailableMeet(date: string, time: string) {
	return await deleteDoc(doc(db, "meets", `${date}|${time}`));
}

async function deleteBookedMeet(date: string, time: string) {
	return await updateDoc(doc(db, "meets", `${date}|${time}`), {
		available: true,
		comment: deleteField(),
		firstCall: deleteField(),
		messenger: deleteField(),
		phone: deleteField(),
		surname: deleteField(),
		isBookedOnline: deleteField(),
		isBookedOffline: deleteField(),
	});
}

export {
	createAvailableMeet,
	getAllMeets,
	getAllAvailableMeets,
	getCurrentAvailableMeets,
	updateAvailableMeet,
	updateBookedMeet,
	bookMeet,
	deleteAvailableMeet,
	deleteBookedMeet,
};
