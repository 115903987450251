import { useState, useEffect, useRef } from "react";

import icons from "../../../assets/images/icons/sprite.svg";

import reviews from "../../../assets/reviews.json";

import Card from "./card/Card";

import styles from "./Reviews.module.scss";

const columnGap = 30;
const largeCardWidth: number = 766;
const mediumCardWidth: number = 570;
const smallCardWidth: number = 350;

const getInitialReviewsTranslate = () => {
	const { clientWidth } = document.documentElement;
	const cardWidth = clientWidth > largeCardWidth ? mediumCardWidth : smallCardWidth;
	const reviewsWidth = cardWidth * reviews.reviews.length + columnGap * (reviews.reviews.length - 1);

	return reviews.reviews.length % 2 ? reviewsWidth / 2 : reviewsWidth / 2 + cardWidth / 2;
};

interface IReviews {
	text: string;
	autor: string;
}

type TReviewList = IReviews[];

function Reviews() {
	const [isAnimation, setIsAnimation] = useState<boolean>(false);
	const [isDisabledButtons, setIsDisabledButtons] = useState<boolean>(false);
	const [reviewList, setReviewList] = useState<TReviewList>(reviews.reviews);
	const [reviewsTranslate, setReviewsTranslate] = useState<number>(getInitialReviewsTranslate());
	const [viewportHeight, setViewportHeight] = useState<number>(0);
	const rewiewsRef = useRef<HTMLDivElement>(null);
	const [clickToSide, setClickToSide] = useState<"prev" | "next" | "">("");

	useEffect(() => {
		const rewiews = rewiewsRef.current;
		if (rewiews) {
			setViewportHeight(rewiews.offsetHeight);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", () => {
			const reviewsTransale = getInitialReviewsTranslate();
			setReviewsTranslate(reviewsTransale);

			const rewiews = rewiewsRef.current;
			if (rewiews) setViewportHeight(rewiews.offsetHeight);
		});
	}, []);

	function handleClickOnNextBtn() {
		const { clientWidth } = document.documentElement;
		const cardWidth = clientWidth > largeCardWidth ? mediumCardWidth : smallCardWidth;

		setIsAnimation(true);
		setReviewsTranslate(reviewsTranslate + cardWidth + columnGap);
		setIsDisabledButtons(true);
		setClickToSide("next");
	}

	function handleClickOnPrevBtn() {
		const { clientWidth } = document.documentElement;
		const cardWidth = clientWidth > largeCardWidth ? mediumCardWidth : smallCardWidth;

		setIsAnimation(true);
		setReviewsTranslate(reviewsTranslate - cardWidth - columnGap);
		setIsDisabledButtons(true);
		setClickToSide("prev");
	}

	function handleReviewsTransitionEnd() {
		const reviewsListCopy = reviewList.slice();

		if (clickToSide === "prev") {
			const indexLastElement = reviewsListCopy.length - 1;
			reviewsListCopy.unshift(reviewsListCopy[indexLastElement]);
			reviewsListCopy.pop();
		} else if (clickToSide === "next") {
			reviewsListCopy.push(reviewsListCopy[0]);
			reviewsListCopy.shift();
		}

		setReviewList(reviewsListCopy);
		setIsAnimation(false);
		setIsDisabledButtons(false);
		setReviewsTranslate(getInitialReviewsTranslate());
		setClickToSide("");
	}

	return (
		<section className={styles.section} id="reviews">
			<h2 className={styles.title}>Отзывы</h2>
			<div className={styles.slider}>
				<div className={styles.viewport} style={{ height: `${viewportHeight}px` }}>
					<div
						className={`${styles.reviews} ${isAnimation ? styles.isAnimation : ""}`}
						onTransitionEnd={handleReviewsTransitionEnd}
						ref={rewiewsRef}
						style={{ transform: `translate(-${reviewsTranslate}px)` }}>
						{reviewList.map((i) => (
							<Card key={i.text} text={i.text} autor={i.autor} />
						))}
					</div>
				</div>

				<div className={styles.buttons}>
					<div className={styles.buttonsСontainer}>
						<div className={styles.buttonWrapper}>
							<button className={styles.sliderButton} onClick={handleClickOnPrevBtn} disabled={isDisabledButtons}>
								<svg width="21" height="21">
									<use href={icons + "#arrow2"} />
								</svg>
							</button>
							<button
								className={`${styles.sliderButton} ${styles.sliderButtonNext}`}
								onClick={handleClickOnNextBtn}
								disabled={isDisabledButtons}>
								<svg width="21" height="21">
									<use href={icons + "#arrow2"} />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Reviews;
