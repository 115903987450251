import { useSelector } from "react-redux";
import { Backdrop, CircularProgress, Paper } from "@mui/material";

import MyAccountPageSelector from "../../../../redux/myAccountPage/MyAccountPageSelector";
import { maximalZIndexValue } from "../../../../helpers/constants";

import Form from "./form/Form";

function AddDay() {
	const { allMeets } = useSelector(MyAccountPageSelector);

	return (
		<>
			<Paper variant="outlined" sx={{ position: "relative" }}>
				<Form />

				{!allMeets && (
					<Backdrop
						invisible
						sx={{
							position: "absolute",
							zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
						}}
						open={!allMeets}>
						<CircularProgress color="warning" />
					</Backdrop>
				)}
			</Paper>
		</>
	);
}

export default AddDay;
