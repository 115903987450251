import photo_png from "../../../assets/images/photos/photo3.png";
import photo_webp from "../../../assets/images/photos/photo3.webp";
import icons from "../../../assets/images/icons/sprite.svg";

import styles from "./Contacts.module.scss";

function Contacts() {
	return (
		<section className={styles.section} id="contacts">
			<div className="container">
				<h2 className={styles.title}>Контакты</h2>
				<div className={styles.wrapper}>
					<picture className={styles.picture}>
						<source type="image/webp" srcSet={photo_webp} />
						<img className={styles.photo} src={photo_png} alt="photoElement" />
					</picture>
					<div className={styles.info}>
						<p className={styles.subtitle}>Буду рада видеть Вас на своих консультациях</p>

						<ul className={styles.contactList}>
							<li className={styles.contactItem}>
								<a
									className={styles.contactLink}
									href="https://yandex.by/maps/-/CDeoALpa"
									target="_blank"
									rel="noopener noreferrer">
									<svg width="21" height="21">
										<use href={icons + "#location"} />
									</svg>
									г. Минск, ул. Платонова 20б, к. 4, каб. 607
								</a>
							</li>

							<li className={styles.contactItem}>
								<a className={styles.contactLink} href="tel:+375296991110">
									<svg width="21" height="21">
										<use href={icons + "#phone"} />
									</svg>
									+375 (29) 699-11-10
								</a>
							</li>

							<li className={styles.contactItem}>
								<p className={styles.contactItemTitle}>«Почта психологу» для анонимных вопросов</p>
								<a className={styles.contactLink} href="mailto:tihonovich.psy@gmail.com">
									<svg width="21" height="19">
										<use href={icons + "#envelope"} />
									</svg>
									tihonovich.psy@gmail.com
								</a>
							</li>
						</ul>

						<ul className={styles.socialList}>
							<li>
								<a className={styles.socialLink} href="https://t.me/+_VQYzmHrI_Y1MTky" target="_blank" rel="noreferrer">
									<svg width="28" height="28">
										<use href={icons + "#telegram"} />
									</svg>
								</a>
							</li>

							<li>
								<a
									className={styles.socialLink}
									href="https://www.instagram.com/tihonovich.nataliya/"
									target="_blank"
									rel="noreferrer">
									<svg width="30" height="30">
										<use href={icons + "#instagram"} />
									</svg>
								</a>
							</li>
							<li>
								<a
									className={styles.socialLink}
									href="viber://add?number=+375296991110"
									target="_blank"
									rel="noreferrer">
									<svg width="36" height="36">
										<use href={icons + "#viber"} />
									</svg>
								</a>
							</li>
							<li>
								<a
									className={styles.socialLink}
									href="https://api.whatsapp.com/send?phone=375296991110"
									target="_blank"
									rel="noreferrer"
									>
									<svg width="30" height="30">
										<use href={icons + "#whatsap"} />
									</svg>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contacts;
