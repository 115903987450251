import { MouseEvent } from "react";
import { Dayjs } from "dayjs";

import { timeConstants } from "../helpers/constants";

function handleClickOnNavigationLink(e: MouseEvent<HTMLAnchorElement>) {
	e.preventDefault();

	const toID = e.currentTarget.hash.slice(1);
	const toSection = document.querySelector<HTMLDivElement>(`#${toID}`);
	const header = document.querySelector<HTMLDivElement>("header");

	if (toSection && header) {
		const shiftUp = header.offsetHeight;
		const topOfSection = toSection.offsetTop - shiftUp;
		window.scrollTo(0, topOfSection);
	}
}

function scrollToTopOfPage() {
	window.scrollTo(0, 0);
}

function getStringDateFromDayjs(date: Dayjs) {
	const dayOfMonth = date.date();
	const month = date.month();
	const year = date.year();

	const transformedDay = dayOfMonth < timeConstants.tenDays ? `0${dayOfMonth}` : dayOfMonth;
	const transformedMonth = month < timeConstants.nineMonths ? `0${month + 1}` : month + 1;

	return `${transformedDay}.${transformedMonth}.${year}`;
}

function getStringTimeFromDayjs(time: Dayjs) {
	const transformedHour = time.hour() < timeConstants.tenHours ? `0${time.hour()}` : time.hour();
	const transformedMinutes = time.minute() < timeConstants.tenMinutes ? `0${time.minute()}` : time.minute();

	return `${transformedHour}:${transformedMinutes}`;
}

function sortDatesInAscendingOrder(dateList: string[]) {
	return dateList.sort((a, b) => {
		const f = new Date(a.split(".").reverse().join("-"));
		const s = new Date(b.split(".").reverse().join("-"));

		return f.getTime() - s.getTime();
	});
}

function getTimestamp(date: string, time: string) {
	const reversedDate = date.split(".").reverse().join(".");
	return new Date(`${reversedDate}.${time}`).getTime();
}

export {
	handleClickOnNavigationLink,
	scrollToTopOfPage,
	sortDatesInAscendingOrder,
	getStringDateFromDayjs,
	getStringTimeFromDayjs,
	getTimestamp,
};
