import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

import styles from "./Fields.module.scss";

interface IDateField {
	meetDate: string;
}

function DateField({ meetDate }: IDateField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Дата слота</p>
			<Select className={styles.select} labelId="date-select-label" id="date" value={meetDate} readOnly>
				<MenuItem value={meetDate}>{meetDate}</MenuItem>
			</Select>
			<FormHelperText> </FormHelperText>
		</FormControl>
	);
}

export default DateField;
