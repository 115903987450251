import React, { ChangeEvent } from "react";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import styles from "./Fields.module.scss";

interface ICommentField {
	value: string;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function CommentField({ value, onChange }: ICommentField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Комментарий клиента</p>
			<TextField className={styles.textField} id="comment" value={value} multiline rows={4} onChange={onChange} />
		</FormControl>
	);
}

export default CommentField;
