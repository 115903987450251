import React, { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { FormControl } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { TimeValidationError } from "@mui/x-date-pickers/models";

import { timeConstants } from "../../../../../../helpers/constants";

import styles from "./Fields.module.scss";

interface IEndTimeField {
	value: Dayjs | null;
	error: TimeValidationError | null;
	slotInterval: string;
	startTimeValue: Dayjs | null;
	startTimeError: TimeValidationError | null;
	onChange: (newValue: Dayjs | null) => void;
	onError: (error: TimeValidationError) => void;
}

function EndTimeField({
	value,
	error,
	slotInterval,
	startTimeValue,
	startTimeError,
	onChange,
	onError,
}: IEndTimeField) {
	const errorMessage = useMemo(() => {
		if (error === "invalidDate") {
			return "введите корректное время";
		} else if (error === "minTime") {
			return "не может быть меньше нач. вр. + интервал";
		} else if (!error) {
			return " ";
		}
	}, [error]);

	const minEndTime = useMemo(() => {
		if (startTimeValue && !startTimeError) {
			const minTime = startTimeValue.add(+slotInterval, "minute");

			if (minTime.hour() < startTimeValue.hour())
				return dayjs().set("hour", timeConstants.twentyThreeHours).endOf("hour");
			return minTime;
		}
	}, [startTimeValue, startTimeError, slotInterval]);

	return (
		<FormControl className={styles.timeControl} fullWidth>
			<p className={styles.fieldLabel}>Время (до)</p>
			<DesktopTimePicker
				className={styles.picker}
				value={value}
				onChange={onChange}
				onError={onError}
				slotProps={{
					textField: {
						helperText: errorMessage,
					},
				}}
				minTime={minEndTime}
				disabled={!startTimeValue || !!startTimeError}
			/>
		</FormControl>
	);
}

export default EndTimeField;
