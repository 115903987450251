import { useMemo } from "react";
import { Dayjs } from "dayjs";

import { FormControl } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateValidationError } from "@mui/x-date-pickers/models";

import styles from "./Fields.module.scss";

interface IDateField {
	value: Dayjs | null;
	error: DateValidationError;
	onChange: (newValue: Dayjs | null) => void;
	onError: (error: DateValidationError) => void;
}

function DateField({ value, error, onChange, onError }: IDateField) {
	const errorMessage = useMemo(() => {
		if (error === "invalidDate") {
			return "введите корректную дату";
		} else if (error === "disablePast") {
			return "невозможно добавить слот на прошедшую дату";
		} else {
			return " ";
		}
	}, [error]);

	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Дата</p>
			<DesktopDatePicker
				className={styles.picker}
				value={value}
				onChange={onChange}
				onError={onError}
				slotProps={{
					textField: {
						helperText: errorMessage,
					},
				}}
				reduceAnimations
			/>
		</FormControl>
	);
}

export default DateField;
