import React, { ChangeEvent } from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PhoneInput, { CountryData } from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/material.css";

import styles from "./Fields.module.scss";

interface IPhoneField {
	value: string;
	isValidPhone: boolean;
	onChange: (value: string, country: CountryData, e: ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
}

function PhoneField({ value, isValidPhone, onChange }: IPhoneField) {
	return (
		<FormControl error={!isValidPhone} fullWidth>
			<p className={styles.fieldLabel}>Номер телефона</p>
			<PhoneInput
				value={value}
				country={"by"}
				placeholder={"Номер телефона"}
				localization={ru}
				specialLabel={""}
				preferredCountries={["by", "ru", "kz"]}
				enableSearch={true}
				inputClass={styles.phoneInput}
				onChange={onChange}
				isValid={isValidPhone}
			/>
			<FormHelperText>{isValidPhone ? " " : "некорректный номер"}</FormHelperText>
		</FormControl>
	);
}

export default PhoneField;
