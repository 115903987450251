import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

interface IConfirmationModal {
	isOpen: boolean;
	onClose: () => void;
	onSave: () => void;
}

function ConfirmationModal({ isOpen, onClose, onSave }: IConfirmationModal) {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{ "& .MuiDialog-paper": { color: "#000" } }}>
			<DialogTitle id="alert-dialog-title" sx={{ color: "#000" }}>
				Сохранить изменения?
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" sx={{ color: "#000" }}>
					Вы действительно хотите сохранить данные изменения?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined" color="inherit" startIcon={<CloseIcon />}>
					Отмена
				</Button>
				<Button onClick={onSave} variant="contained" color="warning" startIcon={<SaveIcon />} autoFocus>
					Сохранить
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationModal;
