import React, { useMemo } from "react";
import { Dayjs } from "dayjs";
import { FormControl } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { TimeValidationError, TimeView } from "@mui/x-date-pickers/models";

import { IAllMeets } from "../../../../../../models/meets";
import { getStringDateFromDayjs } from "../../../../../../utils";
import { timeConstants } from "../../../../../../helpers/constants";

import styles from "./Fields.module.scss";

interface ITimeField {
	value: Dayjs | null;
	dateValue: Dayjs | null;
	allMeets: IAllMeets | null;
	error: TimeValidationError;
	onChange: (newValue: Dayjs | null) => void;
	onError: (error: TimeValidationError) => void;
}

function TimeField({ value, dateValue, allMeets, error, onChange, onError }: ITimeField) {
	const errorMessage = useMemo(() => {
		if (error === "invalidDate") {
			return "введите корректное время";
		} else if (error === "shouldDisableTime-minutes") {
			return "введеное время уже занято";
		} else {
			return " ";
		}
	}, [error]);

	const isDisableTime = (value: Dayjs, view: TimeView) => {
		if (allMeets && dateValue) {
			const convertedDateValue = getStringDateFromDayjs(dateValue);

			if (allMeets[convertedDateValue]?.times) {
				const bookedTimesOfSelectedDate = Object.keys(allMeets[convertedDateValue].times);
				const searchedHour = value.hour() < timeConstants.tenHours ? `0${value.hour()}` : `${value.hour()}`;
				const searchedMinutes = value.minute() < timeConstants.tenMinutes ? `0${value.minute()}` : `${value.minute()}`;

				return view === "minutes" && bookedTimesOfSelectedDate.includes(`${searchedHour}:${searchedMinutes}`);
			}
		}

		return false;
	};

	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Время</p>
			<DesktopTimePicker
				className={styles.picker}
				value={value}
				onChange={onChange}
				onError={onError}
				slotProps={{
					textField: {
						helperText: errorMessage,
					},
				}}
				shouldDisableTime={isDisableTime}
			/>
		</FormControl>
	);
}

export default TimeField;
