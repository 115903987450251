import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";

import { Paper } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";

import { IAllMeets } from "../../../../models/meets";
import MyAccountPageSelector from "../../../../redux/myAccountPage/MyAccountPageSelector";
import { setRelevantMeetList, setSelectedDate } from "../../../../redux/logBook/LogBookSlice";
import { getStringDateFromDayjs } from "../../../../utils";

import MarkedDay from "./markedDay/MarkedDay";

import styles from "./JournalCalendar.module.scss";

interface IJournalCalendar {
	isLoading: boolean;
	date: string;
}

function getMeetList(allMeets: IAllMeets, selectedDate: string) {
	if (allMeets[selectedDate]) {
		const meetTimes = Object.keys(allMeets[selectedDate].times);
		return meetTimes.map((time) => allMeets[selectedDate].times[time]);
	}

	return [];
}

function JournalCalendar({ isLoading, date }: IJournalCalendar) {
	const dispatch = useDispatch();
	const { allMeets } = useSelector(MyAccountPageSelector);

	const handleChangeDate = (date: Dayjs | null) => {
		if (date && allMeets) {
			const selectedDate = getStringDateFromDayjs(date);
			dispatch(setSelectedDate(selectedDate));

			if (allMeets[selectedDate]) {
				const timeList = getMeetList(allMeets, selectedDate);
				dispatch(setRelevantMeetList(timeList));
			} else {
				dispatch(setRelevantMeetList([]));
			}
		}
	};

	return (
		<div className={styles.calendarWrapper}>
			<Paper variant="outlined">
				<DateCalendar
					reduceAnimations
					value={dayjs(date, "DD.MM.YYYY")}
					loading={isLoading}
					onChange={handleChangeDate}
					renderLoading={() => <DayCalendarSkeleton />}
					slots={{
						day: MarkedDay,
					}}
					slotProps={{
						day: {
							allMeets,
						} as any,
					}}
				/>
			</Paper>
			<div className={styles.infoBlock}>
				<p>
					<span className={`${styles.statusCircle} ${styles.busyCircle}`}></span> – все слоты забронированны
				</p>
				<p>
					<span className={`${styles.statusCircle} ${styles.availableCircle}`}></span> – есть доступные слоты
				</p>
			</div>
		</div>
	);
}

export default JournalCalendar;
