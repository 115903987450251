import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAllMeets, IAvailableMeet } from "../../models/meets";
import { IEmail } from "../../models/emails";
import { getTimestamp } from "../../utils";

interface IInitialState {
	allMeets: IAllMeets | null;
	emails: {
		emailList: IEmail[] | null;
		countEmails: number | null;
	};
}

interface IAddAvailableMeetAction {
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
}

interface IAddAvailableMeetsAction {
	date: string;
	times: string[];
	isOnline: boolean;
	isOffline: boolean;
}

interface IDeleteAvailableMeetAction {
	date: string;
	time: string;
}

interface IUpdateAvailableMeetAction {
	date: string;
	time: string;
	isOnline: boolean;
	isOffline: boolean;
}

interface IUpdateBookedMeetAction {
	date: string;
	time: string;
	params: {
		comment: string;
		firstCall: boolean;
		messenger: string;
		phone: string;
		surname: string;
		isBookedOnline: boolean;
		isBookedOffline: boolean;
	};
}

type IBookMeetAction = IUpdateBookedMeetAction;

const initialState: IInitialState = {
	allMeets: null,
	emails: {
		emailList: null,
		countEmails: null,
	},
};

export const myAccountPageSlice = createSlice({
	name: "myAccountPage",
	initialState,
	reducers: {
		setAllMeets: (state, action: PayloadAction<IAllMeets>) => {
			state.allMeets = action.payload;
		},

		setAllEmails: (state, action: PayloadAction<IEmail[]>) => {
			state.emails.emailList = action.payload;
		},

		setCountEmails: (state, action: PayloadAction<number>) => {
			state.emails.countEmails = action.payload;
		},

		addAvailableMeet: (state, action: PayloadAction<IAddAvailableMeetAction>) => {
			if (state.allMeets) {
				const { date, time, isOnline, isOffline } = action.payload;
				const timestamp = getTimestamp(date, time);

				const newAvailableMeet: IAvailableMeet = {
					available: true,
					date,
					time,
					isOnline,
					isOffline,
					timestamp,
				};

				if (state.allMeets[date]) {
					state.allMeets[date].times[time] = newAvailableMeet;
				} else {
					state.allMeets[date] = {
						available: true,
						times: {
							[time]: newAvailableMeet,
						},
					};
				}
			}
		},

		addAvailableMeets: (state, action: PayloadAction<IAddAvailableMeetsAction>) => {
			const { date, times, isOnline, isOffline } = action.payload;

			times.forEach((time) => {
				const timestamp = getTimestamp(date, time);
				const newMeet: IAvailableMeet = {
					available: true,
					date,
					time,
					isOnline,
					isOffline,
					timestamp,
				};

				if (state.allMeets) {
					if (state.allMeets[date]) {
						state.allMeets[date].times[time] = newMeet;
					} else {
						state.allMeets[date] = {
							available: true,
							times: {
								[time]: newMeet,
							},
						};
					}
				}
			});
		},

		updateAvailableMeet: (state, action: PayloadAction<IUpdateAvailableMeetAction>) => {
			const { allMeets } = state;
			const { date, time, isOnline, isOffline } = action.payload;

			if (allMeets) {
				allMeets[date].times[time].isOnline = isOnline;
				allMeets[date].times[time].isOffline = isOffline;
			}
		},

		updateBookedMeet: (state, action: PayloadAction<IUpdateBookedMeetAction>) => {
			const { allMeets } = state;
			const { date, time, params } = action.payload;

			if (allMeets) {
				allMeets[date].times[time] = {
					...allMeets[date].times[time],
					...params,
				};
			}
		},

		bookMeet: (state, action: PayloadAction<IBookMeetAction>) => {
			const { allMeets } = state;
			const { date, time, params } = action.payload;

			if (allMeets) {
				allMeets[date].times[time] = {
					...allMeets[date].times[time],
					...params,
					available: false,
				};

				const timeList = Object.keys(allMeets[date].times);
				const dayAvailable = timeList.some((time) => allMeets[date].times[time].available);
				if (!dayAvailable) allMeets[date].available = false;
			}
		},

		deleteAvailableMeet: (state, action: PayloadAction<IDeleteAvailableMeetAction>) => {
			const { date, time } = action.payload;
			const { allMeets } = state;

			if (allMeets) {
				delete allMeets[date].times[time];

				const timeList = Object.keys(allMeets[date].times);

				if (!timeList.length) {
					delete allMeets[date];
				} else {
					const dayAvailable = timeList.some((time) => allMeets[date].times[time].available);
					if (!dayAvailable) allMeets[date].available = false;
				}
			}
		},

		deleteBookedMeet: (state, action: PayloadAction<IDeleteAvailableMeetAction>) => {
			const { date, time } = action.payload;
			const { allMeets } = state;

			if (allMeets) {
				const deletedMeet = allMeets[date].times[time];

				const newAvailableMeet: IAvailableMeet = {
					available: true,
					date,
					time,
					isOnline: deletedMeet.isOnline,
					isOffline: deletedMeet.isOffline,
					timestamp: deletedMeet.timestamp,
				};

				allMeets[date].times[time] = newAvailableMeet;
				if (!allMeets[date].available) allMeets[date].available = true;
			}
		},

		deleteAllMeets: (state) => {
			state.allMeets = null;
		},

		deleteEmails: (state) => {
			state.emails = {
				emailList: null,
				countEmails: null,
			};
		},
	},
});

export const {
	setAllMeets,
	setAllEmails,
	setCountEmails,
	addAvailableMeet,
	addAvailableMeets,
	updateBookedMeet,
	updateAvailableMeet,
	bookMeet,
	deleteAvailableMeet,
	deleteBookedMeet,
	deleteAllMeets,
	deleteEmails,
} = myAccountPageSlice.actions;

export default myAccountPageSlice.reducer;
