import { Routes, Route, Navigate } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import MyAccountPage from "./pages/MyAccountPage";
import MainPage from "./pages/MainPage";

import Logbook from "./components/myAccountPage/logbook/Logbook";
import Schedule from "./components/myAccountPage/schedule/Schedule";
import Notifications from "./components/myAccountPage/notifications/Notifications";
import { PrivateRoute, PublicRoute } from "./components/common/routes/Routes";

function App() {
	return (
		<Routes>
			<Route path="/" element={<MainPage />} />

			<Route element={<PublicRoute />}>
				<Route path="/login" element={<LoginPage />} />
			</Route>

			<Route element={<PrivateRoute />}>
				<Route path="/my-account" element={<MyAccountPage />}>
					<Route index element={<Navigate to="/my-account/logbook" />} />
					<Route index path="logbook" element={<Logbook />} />
					<Route path="work-schedule" element={<Schedule />} />
					<Route path="notifications" element={<Notifications />} />
				</Route>
			</Route>

			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
}

export default App;
