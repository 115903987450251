import React from "react";
import StarIcon from "@mui/icons-material/Star";

import styles from "./Card.module.scss";

interface ICard {
	text: string;
	autor?: string;
}

function Card({ text, autor }: ICard) {
	return (
		<div className={styles.card}>
			<div>
				<StarIcon sx={{ fill: "#eca555" }} />
				<StarIcon sx={{ fill: "#eca555" }} />
				<StarIcon sx={{ fill: "#eca555" }} />
				<StarIcon sx={{ fill: "#eca555" }} />
				<StarIcon sx={{ fill: "#eca555" }} />
			</div>

			<p className={styles.text}>{text}</p>
			{autor && <p className={styles.autor}>{autor}</p>}
		</div>
	);
}

export default Card;
