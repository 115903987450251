import { combineReducers, configureStore } from "@reduxjs/toolkit";

import myAccountPageSlice from "./myAccountPage/MyAccountPageSlice";
import logbookSlice from "./logBook/LogBookSlice";
import journalTableSlice from "./journalTable/JournalTableSlice";
import ConsultationSlice from "./consultation/ConsultationSlice";

const rootReducer = combineReducers({
	myAccountPageSlice,
	logbookSlice,
	journalTableSlice,
	ConsultationSlice,
});

const store = configureStore({
	reducer: rootReducer,
});

export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
