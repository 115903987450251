import { setDoc, doc, getDocs, collection, getCountFromServer, deleteDoc } from "firebase/firestore";

import { IEmail } from "../../models/emails";

import db from "../config";

async function getAllEmails() {
	const querySnapshot = await getDocs(collection(db, "mail"));
	const allEmails: IEmail[] = [];

	querySnapshot.forEach((doc) => {
		const { info, to, message } = doc.data() as IEmail;

		allEmails.unshift({ info, to, message, id: doc.id });
	});

	return allEmails;
}

async function getCountEmails() {
	const coll = collection(db, "mail");
	const snapshot = await getCountFromServer(coll);

	return snapshot.data().count;
}

async function sendEmail(date: string, time: string, type: string) {
	return await setDoc(doc(db, "mail", `${Date.now()}`), {
		to: "psiholog296@gmail.com",
		message: {
			subject: "Новая запись на консультацию!",
			html: `Уважаемая Наталья! У вас забронирована новая консультация <b>${date}</b> в <b>${time}</b>. <br> С наилучшими пожеланиями ваш покорный виртуальный слуга на веки вечные 💔💔💔`,
		},
		info: {
			date,
			time,
			type,
		},
	});
}

async function deleteAllEmails(mails: IEmail[]) {
	const arr: Promise<void>[] = [];
	mails.forEach((mail) => arr.push(deleteDoc(doc(db, "mail", mail.id))));

	return Promise.all(arr);
}

export { getAllEmails, getCountEmails, sendEmail, deleteAllEmails };
