import { FormControl, FormHelperText, TextField } from "@mui/material";

import styles from "./Fields.module.scss";

function SurnameField() {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Имя клиента</p>
			<TextField className={styles.textField} disabled />
			<FormHelperText> </FormHelperText>
		</FormControl>
	);
}

export default SurnameField;
