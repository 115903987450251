import { useState, ChangeEvent } from "react";
import { Backdrop, Button, CircularProgress, SelectChangeEvent } from "@mui/material";
import { DateValidationError, TimeValidationError } from "@mui/x-date-pickers";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector, useDispatch } from "react-redux";
import { Dayjs } from "dayjs";

import { createAvailableMeet } from "../../../../../db/api/meets";

import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import ErrorAlert from "../../../../common/errorAlert/ErrorAlert";
import SuccessAlert from "../../../../common/successAlert/SuccessAlert";
import LogBookSelector from "../../../../../redux/logBook/LogBookSelector";
import MyAccountPageSelector from "../../../../../redux/myAccountPage/MyAccountPageSelector";
import { addAvailableMeets as addAvailableMeetsToMyAccount } from "../../../../../redux/myAccountPage/MyAccountPageSlice";
import { addAvailableMeets as addAvailableMeetsToLogbook } from "../../../../../redux/logBook/LogBookSlice";
import { addAvailableMeets as addAvailableMeetsToJournalTable } from "../../../../../redux/journalTable/JournalTableSlice";
import { getStringDateFromDayjs, getStringTimeFromDayjs } from "../../../../../utils";
import { maximalZIndexValue } from "../../../../../helpers/constants";

import DateField from "./fields/DateField";
import StartTimeField from "./fields/StartTimeField";
import EndTimeField from "./fields/EndTimeField";
import TypeField from "./fields/TypeField";
import IntervalField from "./fields/IntervalField";

import styles from "./Form.module.scss";

function Form() {
	const dispatch = useDispatch();
	const { allMeets } = useSelector(MyAccountPageSelector);
	const { selectedDate } = useSelector(LogBookSelector);

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);
	const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState<boolean>(false);

	const [slotInterval, setSlotInterval] = useState<string>("60");

	const [dateValue, setDateValue] = useState<Dayjs | null>(null);
	const [dateError, setDateError] = useState<DateValidationError | null>(null);

	const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(null);
	const [startTimeError, setStartTimeError] = useState<TimeValidationError | null>(null);

	const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(null);
	const [endTimeError, setEndTimeError] = useState<TimeValidationError | null>(null);

	const [isOnline, setIsOnline] = useState<boolean>(false);
	const [isOffline, setIsOffline] = useState<boolean>(false);

	const isValidForm = () => {
		return (
			dateValue &&
			!dateError &&
			startTimeValue &&
			!startTimeError &&
			endTimeValue &&
			!endTimeError &&
			(isOnline || isOffline)
		);
	};

	const handleClickOnSubmitButton = async () => {
		if (startTimeValue && endTimeValue && dateValue) {
			setIsSending(true);

			const dateNewDay = getStringDateFromDayjs(dateValue);
			const timesOfNewDay: string[] = [];
			let initialTime = startTimeValue;

			while (endTimeValue.diff(initialTime, "minute") >= +slotInterval) {
				const time = getStringTimeFromDayjs(initialTime);
				timesOfNewDay.push(time);
				initialTime = initialTime.add(+slotInterval, "minute");
			}

			try {
				const slotsOfNewDay = timesOfNewDay.map((time) => createAvailableMeet(dateNewDay, time, isOnline, isOffline));

				await Promise.all(slotsOfNewDay);
				setIsOpenModal(false);
				setIsOpenSuccessAlert(true);
				dispatch(
					addAvailableMeetsToMyAccount({
						date: dateNewDay,
						times: timesOfNewDay,
						isOnline,
						isOffline,
					}),
				);

				if (dateNewDay === selectedDate) {
					dispatch(
						addAvailableMeetsToLogbook({
							date: dateNewDay,
							times: timesOfNewDay,
							isOnline,
							isOffline,
						}),
					);
				}

				dispatch(
					addAvailableMeetsToJournalTable({
						date: dateNewDay,
						times: timesOfNewDay,
						isOnline,
						isOffline,
					}),
				);
			} catch {
				setIsOpenErrorAlert(true);
			}

			setIsSending(false);
		}
	};

	return (
		<>
			<form>
				<ul className={styles.formList}>
					<li>
						<DateField
							value={dateValue}
							error={dateError}
							allMeets={allMeets}
							onChange={(newValue: Dayjs | null) => setDateValue(newValue)}
							onError={(newError) => setDateError(newError)}
						/>
					</li>

					<li>
						<StartTimeField
							value={startTimeValue}
							error={startTimeError}
							slotInterval={slotInterval}
							onChange={(newValue: Dayjs | null) => setStartTimeValue(newValue)}
							onError={(error: TimeValidationError) => setStartTimeError(error)}
						/>
					</li>

					<li>
						<IntervalField value={slotInterval} onChange={(e: SelectChangeEvent) => setSlotInterval(e.target.value)} />
					</li>

					<li>
						<EndTimeField
							value={endTimeValue}
							error={endTimeError}
							slotInterval={slotInterval}
							startTimeValue={startTimeValue}
							startTimeError={startTimeError}
							onChange={(newValue: Dayjs | null) => setEndTimeValue(newValue)}
							onError={(error: TimeValidationError) => setEndTimeError(error)}
						/>
					</li>

					<li>
						<TypeField
							isOnline={isOnline}
							isOffline={isOffline}
							onChangeOnline={(e: ChangeEvent<HTMLInputElement>) => setIsOnline(e.target.checked)}
							onChangeOffline={(e: ChangeEvent<HTMLInputElement>) => setIsOffline(e.target.checked)}
						/>
					</li>

					<li className={styles.buttonItem}>
						<Button
							className={styles.saveButton}
							disabled={!isValidForm()}
							onClick={() => setIsOpenModal(true)}
							variant="contained"
							color="warning"
							startIcon={<SaveIcon />}>
							Сохранить
						</Button>
					</li>
				</ul>
			</form>

			<ConfirmationModal
				isOpen={isOpenModal}
				onClose={() => setIsOpenModal(false)}
				onSubmit={handleClickOnSubmitButton}
				date={dateValue}
				startTime={startTimeValue}
				endTime={endTimeValue}
			/>

			<ErrorAlert
				isOpen={isOpenErrorAlert}
				onClose={() => setIsOpenErrorAlert(false)}
				title={"Ошибка!"}
				body={
					<>
						Произошла ошибка во время добавления нового рабочего дня. <br /> Обновите страницу или попробуйте позже.
					</>
				}
			/>

			<SuccessAlert
				isOpen={isOpenSuccessAlert}
				onClose={() => setIsOpenSuccessAlert(false)}
				title={"Рабочий день добавлен!"}
				body={"Новый рабочий день был успешно добавлен!"}
			/>

			{isSending && (
				<Backdrop
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
					}}
					open={isSending}>
					<CircularProgress color="warning" thickness={5} size={55} />
				</Backdrop>
			)}
		</>
	);
}

export default Form;
