import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";

import { store } from "./redux/store";

import { AuthProvider } from "./authContext/AuthContext";

import App from "./App";

import "./index.scss";
import "./App.scss";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
			<BrowserRouter>
				<AuthProvider>
					<Provider store={store}>
						<App />
					</Provider>
				</AuthProvider>
			</BrowserRouter>
		</LocalizationProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
