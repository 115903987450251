import { IAvailableMeet, IBookedMeet, isIAvailableMeet } from "../../../../../models/meets";

import ModalForBookedMeet from "./modalForBookedMeet/ModalForBookedMeet";
import ModalForAvailableMeet from "./modalForAvailableMeet/ModalForAvailableMeet";

interface IEditingModal {
	isOpen: boolean;
	meet: IAvailableMeet | IBookedMeet;
	onClose: () => void;
}

function EditingModal({ meet, onClose }: IEditingModal) {
	return !isIAvailableMeet(meet) ? (
		<ModalForBookedMeet meet={meet} onClose={onClose} />
	) : (
		<ModalForAvailableMeet meet={meet} onClose={onClose} />
	);
}

export default EditingModal;
