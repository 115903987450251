import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

import useAuth from "../../../authContext/AuthContext";

interface IRouteProps {
	redirectPath?: string;
	children?: ReactElement;
}

function PublicRoute({ redirectPath = "/my-account/logbook", children }: IRouteProps) {
	const { user } = useAuth();

	if (user) return <Navigate to={redirectPath} replace />;

	return children ? children : <Outlet />;
}

function PrivateRoute({ redirectPath = "/", children }: IRouteProps) {
	const { user } = useAuth();

	if (!user) return <Navigate to={redirectPath} replace />;

	return children ? children : <Outlet />;
}

export { PublicRoute, PrivateRoute };
