import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

import styles from "./Fields.module.scss";

interface ITimeField {
	meetTime: string;
}

function TimeField({ meetTime }: ITimeField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Время слота</p>
			<Select className={styles.select} labelId="time-select-label" id="time" value={meetTime} readOnly>
				<MenuItem value={meetTime}>{meetTime}</MenuItem>
			</Select>
			<FormHelperText> </FormHelperText>
		</FormControl>
	);
}

export default TimeField;
