import React from "react";
import { Dayjs } from "dayjs";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import QueueIcon from "@mui/icons-material/Queue";

import { getStringDateFromDayjs, getStringTimeFromDayjs } from "../../../../../utils";

interface IConfirmationModal {
	isOpen: boolean;
	onSubmit: () => Promise<void>;
	onClose: () => void;
	date: Dayjs | null;
	time: Dayjs | null;
}

function ConfirmationModal({ isOpen, onClose, onSubmit, date, time }: IConfirmationModal) {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{ "& .MuiDialog-paper": { color: "#000" } }}>
			<DialogTitle id="alert-dialog-title" sx={{ color: "#000" }}>
				Добавить свободный слот?
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" sx={{ color: "#000" }}>
					Вы действительно хотите добавить новый свободный слот <b>{date && getStringDateFromDayjs(date)}</b> в&nbsp;
					<b>{time && getStringTimeFromDayjs(time)}</b>?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined" color="inherit" startIcon={<CloseIcon />}>
					Отмена
				</Button>
				<Button onClick={onSubmit} variant="contained" color="warning" startIcon={<QueueIcon />} autoFocus>
					Добавить
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationModal;
