import React, { useState, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, Typography } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import ErrorAlert from "../../../../../common/errorAlert/ErrorAlert";
import DeleteModal from "../deleteModal/DeleteModal";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import {
	setStateSuccessAlert,
	updateAvailableMeet as updateAvailableMeetFromJournalTable,
} from "../../../../../../redux/journalTable/JournalTableSlice";
import { updateAvailableMeet as updateAvailableMeetFromMyAccount } from "../../../../../../redux/myAccountPage/MyAccountPageSlice";
import { updateAvailableMeet as updateAvailableMeetFromLogbook } from "../../../../../../redux/logBook/LogBookSlice";
import { maximalZIndexValue } from "../../../../../../helpers/constants";
import { updateAvailableMeet as updateAvailableMeetAPI } from "../../../../../../db/api/meets";
import { IAvailableMeet } from "../../../../../../models/meets";
import LogBookSelector from "../../../../../../redux/logBook/LogBookSelector";

import SurnameField from "./formFields/SurnameField";
import DateField from "./formFields/DateField";
import TimeField from "./formFields/TimeField";
import TypeField from "./formFields/TypeField";
import PhoneField from "./formFields/PhoneField";
import FirstCallField from "./formFields/FirstCallField";
import MessengerField from "./formFields/MessengerField";
import CommentField from "./formFields/CommentField";

import styles from "./ModalForAvailableMeet.module.scss";

interface IModalForAvailableMeet {
	meet: IAvailableMeet;
	onClose: () => void;
}

function ModalForAvailableMeet({ meet, onClose }: IModalForAvailableMeet) {
	const dispatch = useDispatch();
	const { selectedDate } = useSelector(LogBookSelector);

	const [isCheckedOnlineCheckbox, setIsCheckedOnlineCheckbox] = useState<boolean>(meet.isOnline);
	const [isCheckedOfflineCheckbox, setIsCheckedOfflineCheckbox] = useState<boolean>(meet.isOffline);

	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<boolean>(false);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);

	const handleOpenDeleteModal = () => {
		setIsOpenDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setIsOpenDeleteModal(false);
	};

	const isEditedForm = () => {
		return meet.isOnline !== isCheckedOnlineCheckbox || meet.isOffline !== isCheckedOfflineCheckbox;
	};

	const isValidForm = () => {
		return isCheckedOnlineCheckbox || isCheckedOfflineCheckbox;
	};

	const handleClickOnSaveButton = () => {
		setIsOpenConfirmationModal(true);
	};

	const handleClickOnSubmitButton = async () => {
		setIsSending(true);

		try {
			await updateAvailableMeetAPI(selectedDate, meet.time, isCheckedOnlineCheckbox, isCheckedOfflineCheckbox);

			dispatch(setStateSuccessAlert({ isOpen: true, type: "change" }));

			const updatedMeet = {
				date: meet.date,
				time: meet.time,
				isOnline: isCheckedOnlineCheckbox,
				isOffline: isCheckedOfflineCheckbox,
			};

			dispatch(updateAvailableMeetFromLogbook(updatedMeet));
			dispatch(updateAvailableMeetFromJournalTable(updatedMeet));
			dispatch(updateAvailableMeetFromMyAccount(updatedMeet));
			onClose();
		} catch {
			setIsOpenErrorAlert(true);
		}

		setIsSending(false);
	};

	return (
		<>
			<Modal disableAutoFocus open={true} onClose={onClose}>
				<Box className={styles.box} sx={{ boxShadow: 24 }}>
					<div className={styles.innerBox}>
						<IconButton className={styles.closeButton} onClick={onClose}>
							<CloseIcon />
						</IconButton>
						<Typography id="modal-modal-title" fontSize="25px" component="h2">
							Просмотр и редактирование слота <br /> от <b>{selectedDate}</b> на <b>{meet.time}</b>
						</Typography>

						<form className={styles.form}>
							<ul className={styles.formList}>
								<li>
									<SurnameField />
								</li>

								<li>
									<DateField meetDate={meet.date} />
								</li>

								<li>
									<TimeField meetTime={meet.time} />
								</li>

								<li>
									<TypeField
										isCheckedOnline={isCheckedOnlineCheckbox}
										isCheckedOffline={isCheckedOfflineCheckbox}
										onChangeOnline={(e: ChangeEvent<HTMLInputElement>) => setIsCheckedOnlineCheckbox(e.target.checked)}
										onChangeOffline={(e: ChangeEvent<HTMLInputElement>) =>
											setIsCheckedOfflineCheckbox(e.target.checked)
										}
									/>
								</li>

								<li>
									<PhoneField />
								</li>

								<li className={styles.firstCallItem}>
									<FirstCallField />
								</li>

								<li>
									<MessengerField />
								</li>

								<li>
									<CommentField />
								</li>
							</ul>
						</form>

						<div className={styles.buttonWrapper}>
							<Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleOpenDeleteModal}>
								Удалить
							</Button>
							<div className={styles.saveAndCancelButtonWrapper}>
								<Button onClick={onClose} variant="outlined" color="inherit" startIcon={<CloseIcon />}>
									Отмена
								</Button>
								<Button
									onClick={handleClickOnSaveButton}
									disabled={!isValidForm() || !isEditedForm()}
									variant="contained"
									color="warning"
									startIcon={<SaveIcon />}>
									Сохранить
								</Button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>

			<DeleteModal
				date={selectedDate}
				time={meet.time}
				isOpen={isOpenDeleteModal}
				onClose={handleCloseDeleteModal}
				isAvailableTime={meet.available}
				onDelete={onClose}
			/>

			<ConfirmationModal
				isOpen={isOpenConfirmationModal}
				onClose={() => setIsOpenConfirmationModal(false)}
				onSave={handleClickOnSubmitButton}
			/>

			<ErrorAlert
				isOpen={isOpenErrorAlert}
				onClose={() => setIsOpenErrorAlert(false)}
				title={"Ошибка!"}
				body={
					<>
						Произошла ошибка во время сохранения изменений. <br /> Обновите страницу или попробуйте позже
					</>
				}
			/>

			{isSending && (
				<Backdrop
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
					}}
					open={isSending}>
					<CircularProgress color="warning" thickness={5} size={55} />
				</Backdrop>
			)}
		</>
	);
}

export default ModalForAvailableMeet;
