import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

function FirstCallField() {
	return (
		<FormControl>
			<FormControlLabel control={<Checkbox id="firstCall" color="warning" disabled />} label="Был первый звонок" />
			<FormHelperText> </FormHelperText>
		</FormControl>
	);
}

export default FirstCallField;
