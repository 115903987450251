import { useMemo } from "react";
import { Dayjs } from "dayjs";
import { FormControl } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateValidationError } from "@mui/x-date-pickers/models";

import { IAllMeets } from "../../../../../../models/meets";
import { getStringDateFromDayjs } from "../../../../../../utils";

import styles from "./Fields.module.scss";

interface IDateField {
	value: Dayjs | null;
	error: DateValidationError | null;
	allMeets: IAllMeets | null;
	onChange: (newValue: Dayjs | null) => void;
	onError: (error: DateValidationError) => void;
}

function DateField({ value, error, allMeets, onChange, onError }: IDateField) {
	const errorMessage = useMemo(() => {
		if (error === "invalidDate") {
			return "введите корректную дату";
		} else if (error === "disablePast") {
			return "невозможно добавить слот на прошедшую дату";
		} else if (error === "shouldDisableDate") {
			return "выбранная дата уже имеет расписание на день";
		} else {
			return " ";
		}
	}, [error]);

	const isDisabled = (date: Dayjs) => {
		if (allMeets) {
			const dateList = Object.keys(allMeets);
			const convertedDate = getStringDateFromDayjs(date);

			return dateList.includes(convertedDate);
		}

		return false;
	};
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Дата</p>
			<DesktopDatePicker
				className={styles.picker}
				value={value}
				onChange={onChange}
				onError={onError}
				slotProps={{
					textField: {
						helperText: errorMessage,
					},
				}}
				shouldDisableDate={isDisabled}
				reduceAnimations
			/>
		</FormControl>
	);
}

export default DateField;
