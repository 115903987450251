import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import ErrorAlert from "../components/common/errorAlert/ErrorAlert";
import Header from "../components/mainPage/header/Header";
import { getCountEmails } from "../db/api/email";
import { getAllMeets } from "../db/api/meets";
import { setAllMeets, setCountEmails } from "../redux/myAccountPage/MyAccountPageSlice";

import styles from "./MyAccountPage.module.scss";

function MyAccountPage() {
	const dispatch = useDispatch();
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);

	useEffect(() => {
		const loadingMeets = async () => {
			try {
				const [meets, countEmails] = await Promise.all([getAllMeets(), getCountEmails()]);
				dispatch(setAllMeets(meets));
				dispatch(setCountEmails(countEmails));
			} catch (e) {
				setIsOpenErrorAlert(true);
			}
		};

		loadingMeets();
	}, []);

	const handleCloseErrorAlert = () => {
		setIsOpenErrorAlert(false);
	};

	return (
		<>
			<Header />
			<main className={styles.main}>
				<Outlet />
			</main>

			<ErrorAlert
				isOpen={isOpenErrorAlert}
				onClose={handleCloseErrorAlert}
				title={"Ошибка Загрузки!"}
				body={"Ошибка загрузки данных. Обновите страницу или попробуйте позже."}
			/>
		</>
	);
}

export default MyAccountPage;
