import React, { ChangeEvent } from "react";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

import styles from "./Fields.module.scss";

interface ITypeField {
	isCheckedOnline: boolean;
	isCheckedOffline: boolean;
	onChangeOnline: (e: ChangeEvent<HTMLInputElement>) => void;
	onChangeOffline: (e: ChangeEvent<HTMLInputElement>) => void;
}

function TypeField({ isCheckedOnline, isCheckedOffline, onChangeOnline, onChangeOffline }: ITypeField) {
	return (
		<FormControl error={!isCheckedOnline && !isCheckedOffline} fullWidth>
			<p className={styles.fieldLabel}>Тип слота</p>
			<div className={styles.typeCheckboxWrapper}>
				<FormControlLabel
					control={<Checkbox onChange={onChangeOnline} color="warning" checked={isCheckedOnline} />}
					label="онлайн"
				/>
				<FormControlLabel
					control={<Checkbox onChange={onChangeOffline} color="warning" checked={isCheckedOffline} />}
					label="очно"
				/>
			</div>
			<FormHelperText>
				{!isCheckedOnline && !isCheckedOffline ? "необходимо выбрать один или более типов" : " "}
			</FormHelperText>
		</FormControl>
	);
}

export default TypeField;
