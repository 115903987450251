import React from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./Fields.module.scss";

interface IDateField {
	value: string;
	dateList: string[];
	meetDate: string;
	onChange: (e: SelectChangeEvent) => void;
}

function DateField({ value, dateList, meetDate, onChange }: IDateField) {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Дата слота</p>
			<Select
				className={styles.select}
				labelId="date-select-label"
				id="date"
				value={dateList.length ? value : "loading"}
				onChange={onChange}>
				{!dateList.length && <MenuItem value="loading">Загрузка...</MenuItem>}
				{dateList.length &&
					dateList.map((date) => (
						<MenuItem key={date} value={date} sx={date === meetDate ? { fontWeight: 600 } : undefined}>
							{date}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
}

export default DateField;
