import React from "react";
import { FormControl, Select } from "@mui/material";

import styles from "./Fields.module.scss";

function MessengerField() {
	return (
		<FormControl fullWidth>
			<p className={styles.fieldLabel}>Мессенджер для связи</p>
			<Select className={styles.select} value="" labelId="messenger-select-label" id="messenger" disabled></Select>
		</FormControl>
	);
}

export default MessengerField;
