import {
	useState,
	// useRef,
	ChangeEvent,
	FormEvent,
	useEffect,
	SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { FormControl, FormHelperText, InputLabel, MenuItem, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { ruRU } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import emailjs from "@emailjs/browser";
import "react-phone-input-2/lib/material.css";
import ru from "react-phone-input-2/lang/ru.json";

// import { sendEmail } from "../../../db/api/email";
// import { getCurrentAvailableMeets } from "../../../db/api/meets";
// import { IAvailableMeets } from "../../../models/meets";
// import { sortDatesInAscendingOrder } from "../../../utils";
import ErrorAlert from "../../common/errorAlert/ErrorAlert";
import SuccessAlert from "../../common/successAlert/SuccessAlert";
import ConsultationSelector from "../../../redux/consultation/ConsultationSelector";
import { emailData, privacyPolicyLink } from "../../../helpers/constants";

import icons from "../../../assets/images/icons/sprite.svg";
import loading_animation from "../../../assets/images/loading-animation.gif";

import styles from "./LeaveRequest.module.scss";

const inputlabelStyles = { "&.Mui-focused": { color: "#eca555" } };

const selectStyles = {
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "#eca555",
	},
};

const TextFieldStyles = {
	"& .MuiFormLabel-root.Mui-focused": {
		color: "#eca555",
	},
	"& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "#eca555",
	},
};

const rusLocale = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

function LeaveRequest() {
	// const availableMeetsRef = useRef<IAvailableMeets>({});

	// const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isDirtySubmit, setIsDirtySubmit] = useState<boolean>(false);
	// const [isOpenLoadingErrorAlert, setIsOpenLoadingErrorAlert] = useState<boolean>(false);
	const [isOpenSendingErrorAlert, setIsOpenSendingErrorAlert] = useState<boolean>(false);
	const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState<boolean>(false);

	const obligatoryFieldLine: string = "обязательное поле";

	// const [selectedDate, setSelectedDate] = useState<string>("");
	const [dateValue, setDateValue] = useState<Dayjs | null>(null);

	// const [dateList, setDateList] = useState<string[]>([]);

	// const [selectedTime, setSelectedTime] = useState<string>("");
	// const [timeList, setTimeList] = useState<string[]>([]);
	const [timeValue, setTimeValue] = useState<Dayjs | null>(null);

	const [selectedType, setSelectedType] = useState<string>("");
	// const [typeList, setTypeList] = useState<("online" | "offline")[]>([]);

	const [phone, setPhone] = useState<string>("");
	const [isValidPhone, setIsValidPhone] = useState<boolean>(false);

	const [surname, setSurname] = useState<string>("");
	const [surnameError, setSurnameError] = useState<string | null>(obligatoryFieldLine);
	const [isDisabledSurname, setIsDisabledSurame] = useState<boolean>(false);

	const [messenger, setMessenger] = useState<string>("");

	const [comment, setComment] = useState<string>("");
	const [commentError, setCommentError] = useState<string | null>(null);

	const selectedStateType = useSelector(ConsultationSelector).selectedType;

	const inputMaxLimitNumber: number = 40;
	const textAreaMaxLimitNumber: number = 200;
	const timeValueLength: number = 5;

	useEffect(() => {
		if (selectedStateType) {
			setSelectedType(selectedStateType);
		}
	}, [selectedStateType]);

	// useEffect(() => {
	// 	const loadAvailableMeets = async () => {
	// 		try {
	// 			const availableMeets = await getCurrentAvailableMeets();
	// 			// const dateList = sortDatesInAscendingOrder(Object.keys(availableMeets));
	// 			availableMeetsRef.current = availableMeets;

	// 			// setDateList(dateList);
	// 		} catch {
	// 			setIsOpenLoadingErrorAlert(true);
	// 		}

	// 		// setIsLoading(false);
	// 	};

	// 	loadAvailableMeets();
	// }, []);
	
	function removeSecondsFromTimeValue(timeValue: Dayjs) {
		const correctedTimeValue = timeValue.toDate().toLocaleTimeString();

		return correctedTimeValue.slice(0, timeValueLength);
	}

	// const handleChangeDate = (e: SelectChangeEvent) => {
	// 	const selectedDate = e.target.value;

	// 	setSelectedDate(selectedDate);
	// 	setTimeList(Object.keys(availableMeetsRef.current[selectedDate].times));
	// 	setSelectedTime("");
	// 	setSelectedType("");
	// 	setTypeList([]);
	// };

	// function getCorrectDate() {
	// 	const { getUTCDate } = dateValue as Date;

	// 	return "";
	// }

	const handleChangeDate = (newValue: SetStateAction<Dayjs | null>) => {
		return setDateValue(newValue);
	};

	const handleChangeTime = (newValue: SetStateAction<Dayjs | null>) => {
		return setTimeValue(newValue);
	};

	// const handleChangeTime = (e: SelectChangeEvent) => {
	// 	const selectedTime = e.target.value;
	// 	const meet = availableMeetsRef.current[selectedDate].times[selectedTime];
	// 	const typeList: ("online" | "offline")[] = [];
	// 	if (meet.isOnline) typeList.push("online");
	// 	if (meet.isOffline) typeList.push("offline");

	// 	setSelectedTime(e.target.value);
	// 	setTypeList(typeList);
	// 	setSelectedType("");
	// };

	const handleChangeType = (e: SelectChangeEvent) => {
		setSelectedType(e.target.value);
	};

	const handleChangeSurname = (e: ChangeEvent<HTMLInputElement>) => {
		setSurname(e.target.value);

		if (!e.target.value) {
			setSurnameError(obligatoryFieldLine);
		} else if (e.target.value.length > inputMaxLimitNumber) {
			setSurnameError("не может быть длиннее 40 символов");
		} else if (e.target.value) {
			setSurnameError("");
		}
	};

	const handleChangeShowSurname = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setSurname("");
			setIsDisabledSurame(true);
			setSurnameError("");
		} else {
			setIsDisabledSurame(false);
			setSurnameError(obligatoryFieldLine);
		}
	};

	const handleChangeMessenger = (e: SelectChangeEvent) => {
		setMessenger(e.target.value);
	};

	const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);

		if (e.target.value.length > textAreaMaxLimitNumber) {
			setCommentError("максимальная длина 200 символов");
		} else {
			setCommentError(null);
		}
	};

	async function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setIsDirtySubmit(true);

		if (isValidForm()) {
			try {
				setIsSending(true);

				// const isBookedOnline = typeList.includes("online");

				// await bookMeet(selectedDate, selectedTime, phone, surname, isBookedOnline, !isBookedOnline, messenger, comment);

				const templateParams = {
					date: dateValue?.toDate().toLocaleDateString(),
					time: removeSecondsFromTimeValue(timeValue as Dayjs),
					type: selectedType,
					name: surname ?? "",
					phone: phone,
					messenger: messenger,
					comment: comment,
					domain: "localhost",
				};

				emailjs.send(emailData.serviceId, emailData.templateId, templateParams, emailData.publicKey);

				// await sendEmail(selectedDate, selectedTime, selectedType).catch(() => {});

				// delete availableMeetsRef.current[selectedDate].times[selectedTime];

				// if (!Object.keys(availableMeetsRef.current[selectedDate].times).length) {
				// 	delete availableMeetsRef.current[selectedDate];
				// }

				setIsSending(false);
				setIsDirtySubmit(false);

				setDateValue(null);
				// setSelectedDate("");
				// setDateList(sortDatesInAscendingOrder(Object.keys(availableMeetsRef.current)));

				setTimeValue(null);
				// setSelectedTime("");
				// setTimeList([]);

				setSelectedType("");
				// setTypeList([]);

				setPhone("");
				setIsValidPhone(false);

				setSurname("");
				setIsDisabledSurame(false);

				setMessenger("");
				setComment("");

				setIsOpenSuccessAlert(true);
			} catch {
				setIsSending(false);
				setIsOpenSendingErrorAlert(true);
			}
		}
	}

	const isValidForm = () => {
		return !!timeValue && dateValue && selectedType && (isDisabledSurname || surname) && isValidPhone && !commentError;
	};

	// const handleCloseLoadingErrorAlert = () => {
	// 	setIsOpenLoadingErrorAlert(false);
	// };

	const handleCloseSendingErrorAlert = () => {
		setIsOpenSendingErrorAlert(false);
	};

	const handleCloseSuccessAlert = () => {
		setIsOpenSuccessAlert(false);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru" localeText={rusLocale}>
			<>
				<section className={styles.section} id="leaveRequest">
					<div className="container">
						<h2 className={styles.title}>Записаться</h2>
						<div className={styles.wrapper}>
							<div className={styles.leftColumn}>
								<p className={styles.subtitle}>Оставьте заявку на консультацию*</p>
								<p className={styles.leadText}>
									После оставления заявки специалист в течение 24ч с вами свяжется для уточнения запроса и проведении
									мини-консультации БЕСПЛАТНО
								</p>
								<p className={styles.footnote}>
									* Консультация осуществляется после оплаты.
									<br />* Консультация день в день - только после подтверждения специалиста и при наличии мест.
								</p>
								<form className={styles.form} onSubmit={handleFormSubmit} noValidate>
									<ul className={styles.fieldList}>
										<li>
											<FormControl error={isDirtySubmit && !dateValue} fullWidth>
												<DatePicker
													label="Дата приёма"
													sx={TextFieldStyles}
													value={dateValue}
													disablePast
													onChange={handleChangeDate}
													slotProps={{
														textField: {
															error: isDirtySubmit && !dateValue,
														},
													}}
												/>
												<FormHelperText>{isDirtySubmit && !dateValue ? obligatoryFieldLine : " "}</FormHelperText>
											</FormControl>
										</li>

										<li>
											<FormControl error={isDirtySubmit && !timeValue} fullWidth>
												<TimePicker
													label="Время приема (UTC+3)"
													sx={TextFieldStyles}
													value={timeValue}
													onChange={handleChangeTime}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
													}}
													slotProps={{
														textField: {
															error: isDirtySubmit && !timeValue,
														},
													}}
												/>
												<FormHelperText>{isDirtySubmit && !timeValue ? obligatoryFieldLine : " "}</FormHelperText>
											</FormControl>
										</li>

										<li>
											<FormControl error={isDirtySubmit && !selectedType} fullWidth>
												<InputLabel id="type-select-label" sx={inputlabelStyles}>
													Тип приема
												</InputLabel>
												<Select
													labelId="type-select-label"
													id="type"
													value={selectedType}
													label="Тип приема"
													onChange={handleChangeType}
													sx={selectStyles}>
													<MenuItem value={"очно"}>Очно</MenuItem>
													<MenuItem value={"онлайн"}>Онлайн</MenuItem>
												</Select>
												<FormHelperText>{isDirtySubmit && !selectedType ? obligatoryFieldLine : " "}</FormHelperText>
											</FormControl>
										</li>
										<li>
											<FormControl error={isDirtySubmit && !isValidPhone} fullWidth>
												<PhoneInput
													value={phone}
													country={"by"}
													placeholder={"Номер телефона"}
													localization={ru}
													specialLabel={""}
													preferredCountries={["by", "ru", "kz"]}
													enableSearch={true}
													inputClass={styles.phoneInput}
													onChange={(value, country: CountryData, e, formattedValue: string) => {
														setPhone(formattedValue);
														setIsValidPhone(formattedValue.length === country.format.length);
													}}
													isValid={isDirtySubmit ? isValidPhone : undefined}
												/>
												<FormHelperText>{isDirtySubmit && !isValidPhone ? "некорректный номер" : " "}</FormHelperText>
											</FormControl>
										</li>

										<li>
											<TextField
												value={surname}
												onChange={handleChangeSurname}
												id="name"
												label="Ваше имя"
												variant="outlined"
												disabled={isDisabledSurname}
												error={isDirtySubmit && !!surnameError && !isDisabledSurname}
												helperText={isDirtySubmit && surnameError && !isDisabledSurname ? surnameError : " "}
												sx={TextFieldStyles}
												fullWidth
											/>
										</li>

										<li className={styles.showSurnameItem}>
											<input
												className={styles.showSurnameInput}
												id="show_surname"
												type="checkbox"
												onChange={handleChangeShowSurname}
											/>
											<label className={styles.showSurnameLabel} htmlFor="show_surname">
												Не готов(а) указывать имя
											</label>
										</li>

										<li>
											<FormControl fullWidth>
												<InputLabel id="messenger-select-label" sx={inputlabelStyles}>
													Куда удобнее вам написать
												</InputLabel>
												<Select
													labelId="messenger-select-label"
													id="messenger"
													value={messenger}
													label="Куда удобнее вам написать"
													onChange={handleChangeMessenger}
													sx={selectStyles}>
													<MenuItem value={"Viber"}>
														<div className={styles.selectIcon}>
															<svg width="24" height="24">
																<use href={icons + "#viber"} />
															</svg>
															<span>Viber</span>
														</div>
													</MenuItem>
													<MenuItem value={"Telegram"}>
														<div className={styles.selectIcon}>
															<svg width="18" height="18">
																<use href={icons + "#telegram"} />
															</svg>
															<span>Telegram</span>
														</div>
													</MenuItem>
													<MenuItem value={"WhatsApp"}>
														<div className={styles.selectIcon}>
															<svg width="18" height="18">
																<use href={icons + "#whatsap"} />
															</svg>
															<span>WhatsApp</span>
														</div>
													</MenuItem>
												</Select>
												<FormHelperText> </FormHelperText>
											</FormControl>
										</li>

										<li>
											<FormControl fullWidth>
												<TextField
													id="comment"
													label="Опишите Вашу ситуацию"
													value={comment}
													multiline
													rows={4}
													error={isDirtySubmit && !!commentError}
													onChange={handleChangeComment}
													sx={TextFieldStyles}
													helperText={isDirtySubmit && !!commentError ? commentError : " "}
												/>
											</FormControl>
										</li>
									</ul>
									<div className={styles.wrappButton}>
										<button
											className={`${styles.submitButton} ${
												isDirtySubmit && !isValidForm() ? styles.submitButtonNotValid : ""
											}`}
											disabled={(isDirtySubmit && !isValidForm()) || isSending}>
											{isSending && <img className={styles.loadingImg} src={loading_animation} alt="first loading" />}
											{isSending ? "Отправка..." : "Отправить заявку"}
										</button>

										<a href="https://checkout.bepaid.by/v2/confirm_order/prd_5cca92bd22e4aad0/27475" target="_blank" rel="noreferrer" className={`
											${styles.toBuyService}`}>

											Оплатить
										</a>
									</div>
								</form>

								<p className={styles.privacyPolicyText}>
									Нажимая кнопку &quot;Отправить заявку&quot; вы даете согласие с 
									<a className={styles.privacyPolicyLink} href={privacyPolicyLink}>
										политикой&nbsp;конфиденциальности
									</a>
									.
								</p>
							</div>

							<div className={styles.rightColumn}>
								<svg className={styles.lockIcon}>
									<use href={icons + "#private1"} />
								</svg>
								<p className={styles.infoText}>
									Вся информация, которую вы оставите на сайте или обсудите с психологом конфиденциальна
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* <ErrorAlert
					isOpen={isOpenLoadingErrorAlert}
					onClose={handleCloseLoadingErrorAlert}
					title={"Ошибка Загрузки!"}
					body={"Ошибка загрузки данных. Обновите страницу или попробуйте позже."}
				/> */}

				<ErrorAlert
					isOpen={isOpenSendingErrorAlert}
					onClose={handleCloseSendingErrorAlert}
					title={"Ошибка записи!"}
					body={"Произошла ошибка во время отправки заявки! Обновите страницу или попробуйте позже."}
				/>

				<SuccessAlert
					isOpen={isOpenSuccessAlert}
					onClose={handleCloseSuccessAlert}
					title={"Заявка успешно отправлена!"}
					body={""}
				/>
			</>
		</LocalizationProvider>
	);
}

export default LeaveRequest;
