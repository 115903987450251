import React from "react";

import styles from "./Slogan.module.scss";

function Slogan() {
	return (
		<section className={styles.section}>
			<div className="container">
				<div className={styles.wrapper}>
					<p className={styles.title}>Если есть запрос — обязательно уже есть решение!</p>
					<p className={styles.subtitle}>Нам нужно его найти, подсветить и исполнить.</p>
					<p className={styles.text}>
						Нужна ли вам разовая консультация или долгосрочная поддержка — помните, что вы не одиноки. Я здесь, чтобы
						помочь вам на каждом этапе вашего пути к психологическому благополучию.
					</p>
				</div>
			</div>
		</section>
	);
}

export default Slogan;
