import { MouseEvent } from "react";
import { useDispatch } from "react-redux";

import laptop_png from "../../../assets/images/services/laptop.png";
import laptop_webp from "../../../assets/images/services/laptop.webp";
import diary_png from "../../../assets/images/services/diary.png";
import diary_webp from "../../../assets/images/services/diary.webp";

import { setConsultaionType } from "../../../redux/consultation/ConsultationSlice";
import { handleClickOnNavigationLink } from "../../../utils";
import { typeConsultaion } from "../../../helpers/formHelpers";

import styles from "./Services.module.scss";

function Services() {
	const dispatch = useDispatch();

	function handleOfflineButton(event: MouseEvent<HTMLAnchorElement>) {
		dispatch(setConsultaionType(typeConsultaion.offline));

		return handleClickOnNavigationLink(event);
	}

	function handleOnlineButton(event: MouseEvent<HTMLAnchorElement>) {
		dispatch(setConsultaionType(typeConsultaion.online));

		return handleClickOnNavigationLink(event);
	}

	return (
		<section className={styles.section} id="services">
			<div className="container">
				<h2 className={styles.title}>Услуги</h2>
				<div className={styles.cardWrapper}>
					<div className={styles.card}>
						<picture className={styles.picture}>
							<source type="image/webp" srcSet={laptop_webp} />
							<img className={styles.laptopImage} src={laptop_png} alt="laptop" />
						</picture>

						<p className={styles.cardTitle}>Онлайн консультация</p>
						<ul className={styles.infoList}>
							<li>Встреча длится 60 минут</li>
							<li>Стоимость: 190 BYN (5 500 ₽)</li>
						</ul>
						<a className={styles.toBookLink} href="#leaveRequest" onClick={handleOnlineButton}>
							Записаться
						</a>
					</div>

					<div className={styles.card}>
						<picture className={styles.picture}>
							<source type="image/webp" srcSet={diary_webp} />
							<img className={styles.diaryImage} src={diary_png} alt="diary" />
						</picture>

						<p className={styles.cardTitle}>Очная консультация</p>
						<ul className={styles.infoList}>
							<li>Встреча длится 60 минут</li>
							<li>Стоимость: 190 BYN (5 500 ₽)</li>
						</ul>
						<a className={styles.toBookLink} href="#leaveRequest" onClick={handleOfflineButton}>
							Записаться
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Services;
