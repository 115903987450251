import { Alert, AlertTitle, Snackbar } from "@mui/material";

interface ISuccessAlert {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	body: string | JSX.Element;
}

function SuccessAlert({ isOpen, onClose, title, body }: ISuccessAlert) {
	return (
		<Snackbar open={isOpen} onClose={onClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
			<Alert onClose={onClose} severity="success" sx={{ width: "100%" }} elevation={6} variant="filled">
				<AlertTitle>{title}</AlertTitle>
				{body}
			</Alert>
		</Snackbar>
	);
}

export default SuccessAlert;
