import React, { ChangeEvent } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface IFirstCallField {
	isChecked: boolean;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function FirstCallField({ isChecked, onChange }: IFirstCallField) {
	return (
		<FormControlLabel
			control={<Checkbox id="firstCall" checked={isChecked} onChange={onChange} color="warning" />}
			label="Был первый звонок"
		/>
	);
}

export default FirstCallField;
