import { useState } from "react";
import { useDispatch } from "react-redux";
import {
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import ErrorAlert from "../../../../../common/errorAlert/ErrorAlert";
import {
	deleteAvailableMeet as deleteAvailableMeetAPI,
	deleteBookedMeet as deleteBookedMeetAPI,
} from "../../../../../../db/api/meets";
import {
	deleteAvailableMeet as deleteAvailableMeetFromMyAccount,
	deleteBookedMeet as deleteBookedMeetFromMyAccount,
} from "../../../../../../redux/myAccountPage/MyAccountPageSlice";
import {
	deleteAvailableMeet as deleteAvailableMeetFromLogbook,
	deleteBookedMeet as deleteBookedMeetFromLogbook,
} from "../../../../../../redux/logBook/LogBookSlice";
import {
	setStateSuccessAlert,
	deleteAvailableMeet as deleteAvailableMeetFromJournalTable,
} from "../../../../../../redux/journalTable/JournalTableSlice";
import { maximalZIndexValue } from "../../../../../../helpers/constants";

interface IDeleteModal {
	date: string;
	time: string;
	isAvailableTime: boolean;
	isOpen: boolean;
	onClose: () => void;
	onDelete: () => void;
}

function DeleteModal({ date, time, isOpen, onClose, isAvailableTime, onDelete }: IDeleteModal) {
	const dispatch = useDispatch();
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isOpenErrorAlert, setIsOpenErrorAlert] = useState<boolean>(false);

	const handleClickOnDeleteButton = async () => {
		setIsSending(true);

		try {
			if (isAvailableTime) {
				await deleteAvailableMeetAPI(date, time);
				dispatch(deleteAvailableMeetFromMyAccount({ date, time }));
				dispatch(deleteAvailableMeetFromLogbook(time));
				dispatch(deleteAvailableMeetFromJournalTable({ date, time }));
			} else {
				await deleteBookedMeetAPI(date, time);
				dispatch(deleteBookedMeetFromMyAccount({ date, time }));
				dispatch(deleteBookedMeetFromLogbook(time));
			}

			dispatch(setStateSuccessAlert({ isOpen: true, isAvailableTime, type: "deletion" }));
			onDelete();
		} catch {
			setIsOpenErrorAlert(true);
		}

		setIsSending(false);
	};

	const handleCloseErrorAlert = () => {
		setIsOpenErrorAlert(false);
	};

	return (
		<>
			<Dialog
				open={isOpen}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{ "& .MuiDialog-paper": { color: "#000" } }}>
				<DialogTitle id="alert-dialog-title" sx={{ color: "#000" }}>
					Удалить этот слот?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" sx={{ color: "#000" }}>
						Вы действительно хотите безвозвратно удалить {isAvailableTime ? "свободный" : "забронированный"} слот от{" "}
						<b>{date}</b> на <b>{time}</b>?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant="outlined" color="inherit" startIcon={<CloseIcon />}>
						Отмена
					</Button>
					<Button
						onClick={handleClickOnDeleteButton}
						variant="contained"
						color="error"
						startIcon={<DeleteIcon />}
						autoFocus>
						Удалить
					</Button>
				</DialogActions>
			</Dialog>

			<ErrorAlert
				isOpen={isOpenErrorAlert}
				onClose={handleCloseErrorAlert}
				title={"Ошибка Удаления!"}
				body={"Произошла ошибка во время удаления слота. Обновите страницу или попробуйте позже."}
			/>

			<Backdrop
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + maximalZIndexValue,
				}}
				open={isSending}>
				<CircularProgress color="warning" thickness={5} size={55} />
			</Backdrop>
		</>
	);
}

export default DeleteModal;
